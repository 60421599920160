import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './store';
import './index.css';
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import "ie-array-find-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "classlist-polyfill";

import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

registerServiceWorker();
