import React, {Component} from 'react';
import cn from 'classnames';
import $ from 'jquery';

const Subscribe = ({onSubmit, is_sent}) => (
	<div id="contact" className="contact col-md-6">
		<div className="slogan">
			<img src={require("../../../landing/images/slogan_en.png")}
				 width="100%"
				 height="100%"
				 alt="delta slogan"
			/>
			<div className="slogan-text">Let's stay in touch!</div>
		</div>
		<div className="form">
			<form id="subscribe_form" onSubmit={onSubmit}>
				<input type="hidden" name="project_name" value="Delta Landing" />
				<input type="hidden" name="admin_email" value="buha903@gmail.com" />
				<input type="hidden" name="form_subject" value="Delta Landing Info Request" />

				<div className="form-item">
					<input type="text" name="email" id="email" required
						   placeholder="Leave your email to stay in touch"/>
					<button type="submit" className="form-send">
						<img src={require("../../../landing/images/send.svg")} alt="Send" />
					</button>
				</div>
			</form>

			<div className="example">example: name@example.com</div>

			<div className={cn(
				"message-sent js-message-sent",
				{show: is_sent}
			)}>
				Message Sent
			</div>
		</div>
	</div>
);

class SubscribeContainer extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
	}

	state = {
		is_sent: false
	};

	toggleSentState(state) {
		this.setState({
			is_sent: state
		});
	}

	onSubmit() {
		const $form = $('#subscribe_form');

		$.ajax({
			type: "POST",
			url: "mail.php", //Change
			data: $form.serialize()
		}).done(() => {
			this.toggleSentState(true);

			setTimeout(() => {
				this.toggleSentState(false);
				$form.trigger("reset");
			}, 3000);
		});

		return false;
	}

	render() {
		return (
			<Subscribe
				{...this.state}
				onSubmit={this.onSubmit}
			/>
		)
	}
}

export default SubscribeContainer;