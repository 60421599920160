import term from "./term.json";
import price from "./price.json";
import { getArea, getTerm } from "./../../../selectors";

const Ratios = {
	term,
	price
};

const getListByProp = (type, prop) => Ratios[type][prop] || [];

const getRatioValueData = (type, prop, value) =>
	getListByProp(type, prop).find(
		value_data => value_data.name === value && value_data.type === "ratio"
	) || {};

const getFixedValueData = (type, prop, value) =>
	getListByProp(type, prop).find(
		value_data => value_data.name === value && value_data.type === "fixed"
	) || {};

const getFixedValueByAreaRange = (ranges = [], area) =>
	ranges.find(
		range => area >= range.min && (area <= range.max || !range.max)
	) || {};

const getRatio = (type, prop, value) => {
	if (value instanceof Array) {
		return value.reduce((a, b) => a * getRatio(type, prop, b), 1);
	} else {
		return getRatioValueData(type, prop, value).value || 1;
	}
};

const getFixed = (type, prop, value, params) => {
	if (value instanceof Array) {
		return value.reduce(
			(a, b) => {
				const result = getFixedProxy(type, prop, b, params);

				return {
					items: [...a.items, ...result.items],
					total: a.total + result.total
				};
			},
			{
				items: [],
				total: 0
			}
		);
	} else {
		let total;
		const value_data = getFixedValueData(type, prop, value);

		if (value_data.dependency === "area" && value_data.value) {
			total =
				!value_data.min || value_data.value * params.area >= value_data.min
					? value_data.value * params.area
					: value_data.min;
		} else if (value_data.dependency === "area" && value_data.ranges) {
			total =
				getFixedValueByAreaRange(value_data.ranges, params.area).value || 0;
		} else if (value_data.dependency === "term" && value_data.value) {
			total = value_data.value * params.term + (value_data.min || 0);
		} else if (value_data.dependency === "area_and_term" && value_data.ranges) {
			total =
				(getFixedValueByAreaRange(value_data.ranges, params.area).value || 0) *
				params.term;
		} else if (value_data.type === "fixed" && value_data.ranges) {
			total =
				getFixedValueByAreaRange(value_data.ranges, params.area).value || 0;
		} else if (value_data.type === "fixed" && value_data.value) {
			total = value_data.value;
		} else {
			total = 0;
		}

		return {
			total,
			items: [
				{
					prop,
					value,
					price: total
				}
			]
		};
	}
};

const getFixedProxy = (type, prop, value, params) => {
	const result = getFixed(type, prop, value, params);

	if (result > 0 && !(value instanceof Array)) {
		console.log(`prop: "${prop}"; value: "${value}"; price: "${result}"`);
	}

	return result;
};

const getDataProps = data =>
	Object.keys(data).filter(prop => prop !== "approach");

export default state => {
	const data = state.main.data;

	const params = {
		area: getArea(state),
		term: getTerm(state)
	};

	const price_result = getDataProps(data).reduce(
		(a, b) => a * getRatio("price", b, data[b]),
		1
	);

	const term_result = getDataProps(data).reduce(
		(a, b) => a * getRatio("term", b, data[b]),
		1
	);

	const additional_price = getDataProps(data).reduce(
		(a, b) => {
			const result = getFixedProxy("price", b, data[b], params);

			return {
				items: [...a.items, ...result.items],
				total: a.total + result.total
			};
		},
		{
			items: [],
			total: 0
		}
	) || {
		items: [],
		total: 0
	};

	const approach_ratio = getRatio("price", "approach", data.approach);

	const price_without_approach = price_result * additional_price.total || 0;

	const approach_price_data = {
		prop: "approach",
		value: data.approach,
		price: approach_ratio * price_without_approach
	};

	const total_price = price_without_approach + approach_price_data.price;

	const full_pricing = additional_price.items
		.map(item => ({
			...item,
			price: price_result * item.price
		}))
		.concat([approach_price_data]);

	return {
		price: total_price,
		full_pricing,
		term: term_result
	};
};
