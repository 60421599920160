import React, { Component } from 'react';
import cn from 'classnames';
import {Textbox as Input} from "react-inputs-validation";

class EmailConfirm extends Component {
	render() {
		const {
			code,
			is_verify_code_sent,
			is_verify_code_sending,
			is_verify_code_confirming,
			is_verify_code_confirm_error,
			is_verify_code_send_timeout,
			validate,
			setValidationState,
			onCodeChange,
			onVerifyCodeSend,
			onVerifyCodeConfirm
		} = this.props;

		return (
			<div>
				{!is_verify_code_sent ? (
					<div className="estimate-popup-form">
						<button className={
							cn("button button-next send-verification-code", {loading: is_verify_code_sending})
						}
								onClick={onVerifyCodeSend}
						>
							Send Verification Code
						</button>
					</div>
				) : (
					<div>
						<div className="estimate-popup-form">
							<label htmlFor=""> Verification Code</label>
							<Input
								id="code"
								name="code"
								value={code}
								onChange={(value) => onCodeChange(value)}
								onBlur={() => {}}
								validate={validate}
								validationCallback={(is_error) => {
									setValidationState('code', is_error)
								}}
								validationOption={{
									msgOnError: is_verify_code_confirm_error ? 'Invalid code' : ''
								}}
							/>
							<div className={cn(
								"verification-code-desc",
								{ show: is_verify_code_send_timeout }
							)}>
								We have sent you a confirmation code on your email. If you didn't get it - check your Spam folder or <a onClick={onVerifyCodeSend} className={`send-code-again`}>send again</a>.
							</div>
						</div>
{/* 						<div className="estimate-popup-form">
							<button className={
								cn("button button-next", {loading: is_verify_code_confirming})
							}
									onClick={onVerifyCodeConfirm}
							>
								Verify
							</button>
						</div> */}
					</div>
				)}
			</div>
		)
	}
}

export default EmailConfirm;