import React from 'react';
import {NavLink} from "react-router-dom";

const Calculate = () => (
	<section id="calculate" className="calculate">
		<div className="container _narrow">
			<h2 className="heading">Get your technical offer now.</h2>
			<p className="heading-description">
				Get the most accurate evaluation of your project in a few
				minutes, free of charge.
			</p>
			<div className="btn-container">
				<NavLink replace to={`/building-type`} className="btn btn-red gtm_click_calculate_6">GET THE OFFER</NavLink>
				<a href="#contact" target="_blank"
				   className="btn btn-outline nav-link gtm_click_feedback">LEAVE FEEDBACK</a>
			</div>
		</div>
	</section>
);

export default Calculate;