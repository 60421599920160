import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/scss/swiper.scss';
import $ from 'jquery';

class ProjectsContainer extends Component {
	componentDidMount() {
		$('.swiper-description').click(function() {
			$(this).toggleClass('description-open');
		});
	}

	render () {
		const params = {
			spaceBetween: 30,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				modifierClass: 'swiper-pagination-white swiper-pagination-',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		};

		return (
			<div className="projects-slider">
				<Swiper {...params}>
				<div className="swiper-slide _slide-bmw">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								BMW Showroom
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								AWT Bavaria
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Kyiv, Ukraine
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Project management</li>
									<li>Construction management</li>
									<li>General contractor</li>
									<li>Tender preparation, procurement & processing</li>
									<li>Technical supervision</li>
									<li>Project monitoring (of design documentation - Value
										engineering
									</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2017
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										7,800
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-leoni">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								LEONI Factory
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								LEONI Wiring Systems UA
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Kolomyia, Ukraine
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Architecture</li>
									<li>General planning</li>
									<li>Author supervision</li>
									<li>Tender preparation</li>
									<li>Procurement & Processing</li>
									<li>Technical supervision</li>
									<li>Datapool</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2017
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										8,800
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-ave">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								AVE PLAZA
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								Uniqa Real Estate AG
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Kharkiv, Ukraine
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Specification</li>
									<li>Site supervision</li>
									<li>Project control</li>
									<li>Dealing with authorities</li>
									<li>Tendering and contract management</li>
									<li>Technical due diligence</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2011
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										23,400
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-billa">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								BILLA supermarket
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								REWE International AG
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Kyiv, Ukraine
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Project management</li>
									<li>Architecture</li>
									<li>General planning</li>
									<li>Building certification</li>
									<li>General contractor</li>
									<li>Tender preparation and procurement management</li>
									<li>Consents and liaison with authorities</li>
									<li>Construction management</li>
									<li>Contract management</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2013
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										1,900
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-it">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								Imperial Tobacco Warehouse
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								Imperial Tobacco Production Ukraine
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Kyiv, Ukraine
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Project management</li>
									<li>Site supervision</li>
									<li>Technical supervision</li>
									<li>Dealing with authorities</li>
									<li>Specification</li>
									<li>Management of tendering procedure</li>
									<li>Contract management</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2014
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										3,460
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-kws">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								KWS processing plant
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								KWS Ukraine
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Kamianets-Podilskyi, Ukraine
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Technical supervision</li>
									<li>Accompanying control</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2016
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										13,200
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-ekom">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								Ekom
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								EKOM spol. s.r.o.
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Slovakia
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Architectural design work</li>
									<li>Contract procurement management</li>
									<li>Contract management and construction coordination</li>
									<li>Site supervision and monitoring of conformance with design</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2018
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										1,713
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-ifa">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								IFA Tulln
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								Bundesimmobiliengesellschaft
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Austria
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Architecture (Arge SWAP Architekten with DELTA)</li>
									<li>Site supervision</li>
									<li>Tender Preparation</li>
									<li>Procurement & Processing</li>
									<li>Integrative overall coordination</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2017
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										1,300
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-erber">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								Erber Group RPR
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								Erber AG
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Austria
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>General planning</li>
									<li>Architecture</li>
									<li>Invitations to tender and contract procurement</li>
									<li>Information management</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2018
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										3,891
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-slide _slide-multikraft">
					<div className="swiper-image" />
					<div className="swiper-description">
						<div className="project">
							<div className="project-name">
								Multikraft
							</div>
							<div className="project-title">
								Client:
							</div>
							<div className="project-caption">
								Multikraft Holding GmbH
							</div>
							<div className="project-title">
								Location:
							</div>
							<div className="project-caption">
								Austria
							</div>
							<div className="project-title">
								DELTA Services:
							</div>
							<div className="project-services">
								<ul>
									<li>Invitations to tender</li>
									<li>Site supervision</li>
									<li>Site safety plan</li>
									<li>Project management</li>
								</ul>
							</div>
							<div className="project-info row">
								<div className="col">
									<div className="project-year">
										2017
									</div>
									<div className="project-subtitle">
										Completion
									</div>
								</div>
								<div className="col">
									<div className="project-area">
										3,730
									</div>
									<div className="project-subtitle">
										Floor Area
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Swiper>
			</div>
		);
	}
}

export default ProjectsContainer;