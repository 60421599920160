import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

const Top = () => (
	<section id="main" className="main container-fluid">
		<div className="main-video-container">
			<video playsInline
				   muted
				   poster={require("../../../landing/images/main_top.png")}
				   id="bgvid"
			>
				<source src={require("../../../landing/bmw.mp4")} type="video/mp4"/>
				<source src={require("../../../landing/bmw.webm")} type="video/webm"/>
			</video>
		</div>
		<div className="container">
			<h1>Welcome to DELTA</h1>
			<h2>Get the most accurate evaluation of your project in <br/>a few minutes, free of charge.</h2>

			<div className="play-video-mobile">
				<a href="#videos" className="btn-play-mobile nav-link">PLAY VIDEO</a>
			</div>

			<NavLink replace to={`/building-type`} className="btn btn-red gtm_click_main_1">GET THE OFFER</NavLink>
		</div>
		<div className="play-button-container">
			<a href="javascript:void(0);" id="js_play_bmw" className="btn-play nav-link _pause"/>
		</div>
		<div className="landing-logo">
			<img src={require("../../../landing/images/logo.png")}
				 alt="delta logo"
				 width="100%"
				 height="100%"
			/>
		</div>
	</section>
);

class TopContainer extends Component {
	componentDidMount() {
		const playButton = document.getElementById("js_play_bmw");
		const video = document.getElementById('bgvid');
		const container = document.getElementById('main');

		video.load();

		playButton.addEventListener("click", function() {

			if (video.readyState === 4 && video.paused) {
				this.classList.add('_loading');
				if (!this.classList.contains('_loaded')) {
					setTimeout(function(){
						video.play();
						playButton.classList.remove('_loading');
						playButton.classList.add('_loaded');
						container.classList.add('_play-video');
					}, 3000);
				} else {
					video.play();
					playButton.classList.remove('_loading');
					container.classList.add('_play-video');
				}
				this.classList.add('_playing');
				this.classList.remove('_pause');
			} else {
				video.pause();
				this.classList.remove('_playing');
				this.classList.add('_pause');
				container.classList.remove('_play-video');
			}

			video.onended = function() {
				container.classList.remove('_play-video');
				playButton.classList.remove('_playing');
				playButton.classList.add('_pause');
			};
		});

	}

	render() {
		return (
			<Top />
		)
	}
}

export default TopContainer;