import getRatios from './components/configurator/ratios';
import { getApiUrl, getShareLinkById } from './selectors';
import copyTextToClipboard from './utils/copyTextToClipboard';

import { setContacts } from './views/contacts/actions';

export const SET_STEP = 'SET_STEP';

export const setStep = step => ({
	type: SET_STEP,
	step
});


export const SET_DATA = 'SET_DATA';

export const setData = data => ({
	type: SET_DATA,
	data
});


export const SET_PROP = 'SET_PROP';

export const setProp = (prop, value) => ({
	type: SET_PROP,
	prop,
	value
});


export const SET_PROP_FULL = 'SET_PROP_FULL';

export const setPropFull = (prop, value) => ({
	type: SET_PROP_FULL,
	prop,
	value
});


export const onChange = (prop, value, full_value) => (dispatch) => {
	if (full_value) {
		dispatch(
			setPropFull(prop, full_value)
		);
	}

	dispatch(
		setProp(prop, value)
	);

	dispatch(
		setPrice()
	);
};


export const requestApi = (target, params = {}, method = 'GET') => {
	const url = getApiUrl(target);
	const Url = new URL(url);

	const headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};

	if (method === 'GET') {
		Object.keys(params).forEach(key => (
			Url.searchParams.append(key, params[key])
		));

		return fetch(Url, {
			method: 'GET',
			headers: headers,
		}).then(resp => (
			resp.json()
		));
	}

	if (method === 'POST') {
		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(params)
		}).then(resp => (
			resp.json()
		));
	}
};


export const SET_PRICE = 'SET_PRICE';

export const setPrice = () => (dispatch, getState) => {
	const ratios = getRatios(getState());

	dispatch({
		type: SET_PRICE,
		price: ratios.price,
		full_pricing: ratios.full_pricing
	})
};

export const FETCH_USER_DATA = 'GET_USER_DATA';

export const fetchUserData = (id) => (dispatch, getState) => {
	const project_id = id || getState().main.id;

	dispatch(
		setUserDataFetching(true)
	);

	requestApi('project', {id: project_id})
		.then(response => {
			dispatch(
				setContacts(response.contacts)
			);

			dispatch(
				setData(response.main)
			);

			dispatch(
				setProjectId(response.id)
			);

			dispatch(
				setPrice()
			);

			dispatch(
				setUserDataFetching(false)
			);
		});
};

export const getInvoice = () => () => {
	const url = getApiUrl('invoice');
	const Url = new URL(url);

	const headers = {
		'Accept': 'application/pdf',
		'Content-Type': 'application/pdf'
	};

	fetch(Url, {
		method: 'GET',
		headers: headers,
	}).then(resp => (
		resp.json()
	));
};

export const SET_PROJECT_ID = 'SET_PROJECT_ID';

export const setProjectId = (id) => ({
	type: SET_PROJECT_ID,
	id
});

export const SET_USER_DATA_FETCHING = 'SET_USER_DATA_FETCHING';

export const setUserDataFetching = (is_fetching) => ({
	type: SET_USER_DATA_FETCHING,
	is_fetching
});

export const saveUserData = () => (dispatch, getState) => {
	const data = {
		id: getState().main.id,
		...getState()
	};

	requestApi('project', data, 'POST')
		.then(response => {
			dispatch(
				setProjectId(response.id)
			);

			copyTextToClipboard(
				getShareLinkById(response.id)
			);
		});
};

export const onShare = () => (dispatch, getState) => {
	dispatch(
		saveUserData()
	);

	const link = getShareLinkById(getState().id);

	console.log('SHARE LINK', link);
};

export const TOGGLE_SAVE_POPUP = 'TOGGLE_SAVE_POPUP';

export const toggleSavePopup = is_visible => ({
	type: TOGGLE_SAVE_POPUP,
	is_visible
});

export const TOGGLE_SHARE_POPUP = 'TOGGLE_SHARE_POPUP';

export const toggleSharePopup = is_visible => ({
	type: TOGGLE_SHARE_POPUP,
	is_visible
});

export const fetchCountry = () => (dispatch) => {
	requestApi('country').then((response) => {
		console.log('country', response);

		dispatch({
			type: 'SET_DEFAULT_COUNTRY',
			country: response.country.toLowerCase()
		})
	})
}