import React, { Component } from 'react';

const PrevBtn = ({title, path, path_title, id, onClick}) => (
	!!title && (
		<button
			className="button button-prev"
			onClick={() => onClick(path, id)}
		>
			{path_title}
		</button>
	)
);

const NextBtn = ({title, path, path_title, id, last_step_data, onClick}) => (
	!!title && (
		<button
			className="button button-next"
			onClick={() => onClick(path, id)}
		>
			{path === last_step_data.path ? (
				`${last_step_data.title}`
			) : (
				`${path_title}`
			)}
		</button>
	)
);

class ConfigButtons extends Component {
	render() {
		const {
			prev_step_data,
			next_step_data,
			last_step_data,
			historyPush,
			setStep
		} = this.props;

		const navigate = (path, step) => {
			historyPush(path);
			setStep(step);
		};

		return(
			<div className="buttons">
				<PrevBtn
					{...prev_step_data}
					onClick={navigate}
				/>
				<NextBtn
					{...next_step_data}
					onClick={navigate}
					last_step_data={last_step_data}
				/>
			</div>
		)
	}
}

ConfigButtons.defaultProps = {
	prev_step_data: {},
	next_step_data: {},
	last_step_data: {
		title: "Get Price Now",
		path: "estimated-cost"
	}
};

export default ConfigButtons;