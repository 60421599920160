import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import cn from "classnames";
import { Textbox as Input } from 'react-inputs-validation';

import {
	phoneValidator,
	emailValidator
} from '../validation';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import EmailConfirm from './email_confirm';
import * as Actions from "./actions";

class ContactPopup extends Component {
	state = {
		_mounted: false,
		validate: false,
		validationErrors: {
			full_name: true,
			company_name: true,
			phone: true,
			email: true,
			code: false
		}
	};

	constructor(props) {
		super(props);

		this.onVerifyCodeSend = this.onVerifyCodeSend.bind(this);
		this.onVerifyCodeConfirm = this.onVerifyCodeConfirm.bind(this);
		this.setValidationState = this.setValidationState.bind(this);
	}

	componentDidMount() {
		this.setState({
			_mounted: true
		});
	}

	componentWillUnmount() {
		this.setState({
			_mounted: false
		});
	}

	componentDidUpdate(prevProps) {
		const {
			is_verify_code_confirm_error
		} = this.props;

		if (prevProps.is_verify_code_confirm_error !== is_verify_code_confirm_error &&
			is_verify_code_confirm_error === true
		) {
			this.setValidationState('code', true);

			this.toggleValidating(false, () => {
				this.isFormValid();
			});
		}
	}

	onChange(prop, value) {
		if (!this.state._mounted) {
			return;
		}

		this.props.onChange(prop, value);
	}

	onSubmit() {
		if (this.isFormValid()) {
			this.props.onSubmit(this.props.data)
		}
	}

	onVerifyCodeSend() {
		if (!this.isFormValid()) {
			return;
		}

		this.setValidationState('code', true);

		this.props.onVerifyCodeSend(
			this.props.data.email
		);
	}

	onVerifyCodeConfirm() {
		this.toggleValidating(false, () => {
			if (!this.isFormValid()) {
				return;
			}

			const {
				data
			} = this.props;

			this.props.onVerifyCodeConfirm(data);
		});
	}

	setValidationState(prop, is_error, callback = () => {}) {
		this.setState({
			validationErrors: {
				...this.state.validationErrors,
				[prop]: is_error
			}
		}, callback);
	}

	toggleValidating(validate, cb = () => {}) {
		this.setState({ validate }, cb);
	}

	isFormValid() {
		const errors = this.state.validationErrors;
		const props = Object.keys(errors);
		const is_valid = props.every(prop => errors[prop] === false);

		this.toggleValidating(true);

		return is_valid;
	}

	render() {
		const {
			_mounted,
			validate,
			validationErrors
		} = this.state;

		const {
			is_verify_code_sent,
			is_verify_code_confirmed,
			is_verify_code_confirming,
			default_country,
			data
		} = this.props;

		const {
			full_name,
			company_name,
			phone,
			email,
			code
		} = data;

		return (
			<div>
				<div className="estimate-popup">
					<div className="estimate-popup-title">
						Contact Information
					</div>
					<div className="estimate-popup-subtitle">
						Please provide your contact information before proceed.
					</div>

					{/* Name */}
					<div className="estimate-popup-form">
						<label htmlFor="full_name">Your Name</label>
						<Input
							id="full_name"
							name="full_name"
							value={full_name}
							onChange={(value) => {
								this.onChange('full_name', value)
							}}
							validationCallback={(is_error) => {
								this.setValidationState('full_name', is_error)
							}}
							onBlur={() => {}}
							validate={validate}
						/>
					</div>

					{/* Company name */}
					<div className="estimate-popup-form">
						<label htmlFor="company_name">Company Name</label>
						<Input
							id="company_name"
							name="company_name"
							value={company_name}
							onChange={(value) => {
								this.onChange('company_name', value)
							}}
							validationCallback={(is_error) => {
								this.setValidationState('company_name', is_error)
							}}
							onBlur={() => {}}
							validate={validate}
						/>
					</div>

					{/* Phone */}
					<div className="estimate-popup-form">
						<label htmlFor="phone">Phone Number</label>

						{_mounted && (
							<IntlTelInput
								containerClassName={cn(
									"intl-tel-input",
									{ validation_error: this.state.validationErrors.phone && this.state.validate }
								)}
								inputClassName="form-control"
								separateDialCode={true}
								onPhoneNumberChange={(status, value) => {
									this.setValidationState('phone', !status);
									this.onChange('phone', value)
								}}
								value={phone}
								preferredCountries={['at', 'cz', 'sk', 'ua']}
								defaultCountry={default_country}
							/>
						)}

						{validate && validationErrors.phone &&
							<div className={`react-inputs-validation__msg react-inputs-validation__error`}>phone is not valid</div>
						}
					</div>

					{/* Email */}
					<div className="estimate-popup-form">
						<label htmlFor="">Email Address</label>
						<Input
							id="email"
							name="email"
							value={email}
							onChange={(value) => {
								this.onChange('email', value)
							}}
							validationCallback={(is_error) => {
								this.setValidationState('email', is_error)
							}}
							onBlur={() => {}}
							validate={validate}
							validationOption={{
								customFunc: emailValidator
							}}
						/>
					</div>

					{!is_verify_code_confirmed &&
						<EmailConfirm
							{...this.props}
							code={code}
							is_verify_code_sent={is_verify_code_sent}
							is_verify_code_confirmed={is_verify_code_confirmed}
							onVerifyCodeSend={this.onVerifyCodeSend}
							onVerifyCodeConfirm={this.onVerifyCodeConfirm}
							onCodeChange={(value) => {
								this.onChange('code', value)
							}}
							validate={validate}
							setValidationState={this.setValidationState}
						/>
					}

					{/* Submit Button */}
					{is_verify_code_sent && (
						<div className="estimate-popup-form">
							<button className={
									cn("button button-next", {loading: is_verify_code_confirming})
								}
									onClick={() => this.onVerifyCodeConfirm()}
							>
								Verify & Show Estimated Cost
							</button>
						</div>
					)}

					<div className="estimate-popup-notice">
						Your information will not be shared to outside entities.
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.contacts,
	default_country: state.main.default_country
});

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (data) => dispatch(
		Actions.submitContacts(data)
	),
	onChange: (prop, value) => {
		if (prop === 'code') {
			dispatch(
				Actions.toggleVerifyCodeError(false)
			);
		}

		dispatch(
			Actions.setContactsProp(prop, value)
		)
	},
	onVerifyCodeSend: (email) => dispatch(
		Actions.requestVerificationCode(email)
	),
	onVerifyCodeConfirm: (data) => dispatch(
		Actions.requestVerificationCodeConfirm(data)
	)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactPopup);