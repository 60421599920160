import React from 'react';
import Approach from './approach';

const items = {
	approach: Approach
};

export const get = (name, props) => {
	const CustomItem = items[name];

	return <CustomItem {...props}/>
};

export const isExist = (name) => (
	typeof get(name) !== 'undefined'
);
