import React, {Component} from 'react';
import cn from 'classnames';

import Navigation from './navigation';

const Header = ({is_menu_open, toggleMenu}) => (
	<header className={cn(
		"header container-fluid",
		{'menu-open': is_menu_open})
	}>
		<div className="menu-toggle menu_toggle"
			onClick={toggleMenu}
		>
			<div className="burger-icon" />
		</div>
		<div className="logo-menu-white" />
		<div className="logo-menu" />
		<Navigation />
	</header>
);

class HeaderContainer extends Component {
	constructor(props) {
		super(props);
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	state = {
		is_menu_open: false
	};

	toggleMenu() {
		this.setState({
			is_menu_open: !this.state.is_menu_open
		});
	}

	render() {
		return (
			<Header
				{...this.state}
				toggleMenu={this.toggleMenu}
			/>
		);
	}
}

export default HeaderContainer;