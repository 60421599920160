export const phoneValidator = email => {
	const reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

	if (reg.test(String(email).toLowerCase())) {
		return true;
	} else {
		return "is not a valid phone number";
	}
};

export const emailValidator = email => {
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (reg.test(String(email).toLowerCase())) {
		return true;
	} else {
		return "is not a valid email address";
	}
};