import React, {Component, Fragment} from 'react';
import {NavLink} from "react-router-dom";

const VideoItem = ({src, title, subtitle}) => (
	<div className="projects-video-file col-8 col-md-4 col-sm-6">
		<iframe width="440"
				height="248"
				src={src}
				frameBorder="0"
				allow="autoplay; encrypted-media"
				allowFullScreen
		/>
		<div className="delta-ukraine">
			{title}
		</div>
		<div className="projects-video-title">
			{subtitle}
		</div>
	</div>
);

const Videos = ({is_show_all, showAll}) => (
	<div id="videos" className="projects-video-container container _narrow common-space-bottom">
		<div className="row">
			<div className="col">
				<h3 className="heading">Project Videos</h3>
				<p className="heading-description">
					Have a look at the videos of out buildings.
				</p>
				<div className="projects-video row justify-content-center">
					<VideoItem
						src="https://www.youtube.com/embed/WnWybY3KslE"
						title="DELTA"
						subtitle="DELTA - LEONI Project"
					/>
					<VideoItem
						src="https://www.youtube.com/embed/Df1b2aS2PhA"
						title="DELTA"
						subtitle="DELTA - BMW Project"
					/>
					<VideoItem
						src="https://www.youtube.com/embed/Lv9ePuEXsw8"
						title="DELTA"
						subtitle="Green Building - Supermarket Billa"
					/>
					{is_show_all &&
						<Fragment>
							<VideoItem
								src="https://www.youtube.com/embed/bx2oVZdSCWs"
								title="DELTA"
								subtitle="DELTA - KWS Project"
							/>
							<VideoItem
								src="https://www.youtube.com/embed/jXKjQbkOKGo"
								title="DELTA"
								subtitle="Project Video Multicraft"
							/>
							<VideoItem
								src="https://www.youtube.com/embed/M5AQdjPpEkI"
								title="DELTA"
								subtitle="Project Video Ave Plaza"
							/>
						</Fragment>
					}
				</div>
				<div className="btn-container text-center">
					{!is_show_all &&
						<a className="btn btn-more gtm_click_projects_more js-btn-more"
						   href="javascript:void(0);"
						   onClick={showAll}
						>
							SHOW MORE
						</a>
					}

					<br />
					<NavLink replace to={`/building-type`} className="btn btn-red gtm_click_projects_5">GET THE OFFER</NavLink>
				</div>
			</div>
		</div>
	</div>
);

class VideosContainer extends Component {
	constructor(props) {
		super(props);
		this.showAll = this.showAll.bind(this);
	}

	state = {
		is_show_all: false
	};

	showAll() {
		this.setState({
			is_show_all: true
		})
	}

	render() {
		return (
			<Videos
				{...this.state}
				showAll={this.showAll}
			/>
		)
	}
}

export default VideosContainer;