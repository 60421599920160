import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import classNames from 'classnames';

import * as Actions from "../actions";

import {
	formatPrice,
	formatNumber,
	getShareLinkById,
	getInvoiceUrl
} from '../selectors';

import ContactPopup from './contacts/contacts';
import SharePopup from './share_popup';
import SavePopup from './save_popup';

const POPUP_ACTIVE_CLASS = '_blurred';

const Checkbox = ({data, option}) => (
	(data[option.name] || []).map((value, index) => {
		const match = option.options.find(item => (item.value === value));

		if (match) {
			return (
				<div className="estimate-description" key={`${value}_${index}`}>
					- {match.title}
				</div>
			)
		}

		for (let item of option.options) {
			if (item.options) {
				for (let item_option of item.options) {
					if (item_option.value === value) {
						return (
							<div className="estimate-description" key={`${value}_${index}`}>
								- {`${item.title} / ${item_option.title}`}
							</div>
						)
					}
				}
			}
		}

		return undefined;
	})
);

const EstimateLink = ({className, children, url, id, onClick = () => {}}) => (
	<a className={classNames("estimate-head-link", className)}
	   href={url || "javascript:void(0);"}
	   onClick={onClick}
	   id={id}
	   target={url ? "_blank" : "_self"}
	>
		{children}
	</a>
);

class EstimatedCost extends Component {
	constructor(props) {
		super(props);

		this.closePopup = this.closePopup.bind(this);
	}

	componentDidMount() {
		const {
			params,
			is_fetched,
			is_fetching,
			onLoad
		} = this.props;

		if (params.id && !is_fetched && !is_fetching) {
			onLoad(params.id)
		}
	}

	componentDidUpdate(prevProps) {
		const {is_contacts_submitted} = this.props;

		if (!prevProps.is_contacts_submitted && is_contacts_submitted) {
			this.wrapper.classList.remove(POPUP_ACTIVE_CLASS)
		}
	}

/*	shouldComponentUpdate(nextProps) {
		return false;
	}*/

	closePopup(data) {
		const {onContactsSubmit} = this.props;
		const download_link = document.getElementById('download_link');

		this.wrapper.classList.remove(POPUP_ACTIVE_CLASS);

		setTimeout(() => (
			onContactsSubmit(data)
		), 2000);

		setTimeout(function(){
			window.$crisp.push(["do", "message:show", ["text", "We just created an offer for you! You can download it now. Click the link 'DOWNLOAD OFFER'."]]);
			download_link.classList.add('_pulse');
		}, 8000);
	}

	render() {
		const {
			steps,
			data,
			is_contacts_submitted,
			is_save_popup_visible,
			is_share_popup_visible,
			price = {},
			invoice_url,
			project_url,
			setStep,
			onSave,
			onLoad,
			onShare,
			getInvoice,
			toggleSavePopup,
			toggleSharePopup
		} = this.props;

		return(
			<div className={`estimate-page ${is_contacts_submitted ? '' : POPUP_ACTIVE_CLASS}`}
				 ref={el => this.wrapper = el}
			>
				<div className="estimate-head">
					<div className="estimate-head-title">
						Total Price <span className="price-separator">-</span> <span className="price">{formatPrice(price.total_price)}</span>
					</div>
					<div className="estimate-head-subtitle">
						The cost is approximate, the final cost may be different.
					</div>
					<div className="estimate-head-links">

						<EstimateLink
							className="_save"
							onClick={() => toggleSavePopup(true)}
						>
							Save
						</EstimateLink>

						<span className="estimate-head-separator" />

						<EstimateLink
							className="_download"
							id="download_link"
							url={invoice_url}
						>
							Download Offer
						</EstimateLink>

						<span className="estimate-head-separator" />

						<EstimateLink
							className="_share"
							onClick={() => toggleSharePopup(true)}
						>
							Share
						</EstimateLink>
					</div>
				</div>

				<div className="estimate-steps">
					{steps.filter(step => step.options.length).map(step => (
						<div className="estimate-step" key={`estimated_cost_table_step_${step.index}`}>
							<div className="estimate-step-head">
								<div className="estimate-step-title">
									{step.path_title}
								</div>

								<NavLink
									replace to={`/${step.path}`}
									onClick={() => setStep(step.id)}
								>
									Edit
								</NavLink>
							</div>

							{step.options.filter(option => (
								typeof option.checked === 'undefined' || option.checked
							)).map(option => (
								<div className="estimate-description"
									 key={`estimated_cost_table_option_${step.index}_${option.id}`}
								>
									{option.type === 'select' ? (
										<div className="estimate-description-details">
											<div>- {option.title}</div>
											<div>{(option.options.find(item => item.value === data[option.name]) || {}).label}</div>
										</div>
									) : option.type === 'radio' && option.name === 'building_type' && data[option.name] === 'other' ? ( //done
										<div className="estimate-description-title">
											- Other ({`${data[option.name + '_custom']}`})
										</div>
									) : option.type === 'radio' || option.type === 'approach_radio' ? ( //done
										<div className="estimate-description-title">
											- {(option.options.find(item => item.value === data[option.name]) || {}).title}
										</div>
									) : option.type === 'checkbox' ? (
										<Checkbox data={data} option={option} />
									) : (
										<div className="estimate-description-details">
											<div>- {option.title}</div>
											<div>{formatNumber(data[option.name])}</div>
										</div>
									)}
								</div>
							))}
						</div>
					))}
					{/*<p className="will-contact">Let's discuss your final price!</p>*/}
				</div>

				<ContactPopup />

				{is_share_popup_visible &&
					<SharePopup
						onShare={onShare}
						onClose={() => toggleSharePopup(false)}
					/>
				}

				{is_save_popup_visible &&
					<SavePopup
						url={project_url}
						onSave={onSave}
						onClose={() => toggleSavePopup(false)}
					/>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.main,
	is_contacts_submitted: state.contacts.is_submitted,
	project_url: getShareLinkById(state.main.id),
	invoice_url: getInvoiceUrl({
		full_data: state.main.full_data,
		...(state.main.data || {}),
		...(state.main.price || {}),
		...(state.contacts.data || {})
	})
});

const mapDispatchToProps = (dispatch) => ({
	onLoad: (id) => dispatch(
		Actions.fetchUserData(id)
	),
	onSave: () => dispatch(
		Actions.saveUserData()
	),
	onShare: () => dispatch(
		Actions.onShare()
	),
	toggleSavePopup: (is_visible) => {
		if (is_visible) {
			dispatch(
				Actions.saveUserData()
			)
		}

		dispatch(
			Actions.toggleSavePopup(is_visible)
		);
	},
	toggleSharePopup: (is_visible) => dispatch(
		Actions.toggleSharePopup(is_visible)
	),
	getInvoice: () => dispatch(
		Actions.getInvoice()
	)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EstimatedCost);