import {Textbox as Input} from "react-inputs-validation";
import IntlTelInput from "react-intl-tel-input";
import cn from "classnames";
import React, {Component} from "react";

class CallBack extends Component {
	constructor(props) {
		super(props);

		this.onCallClick = this.onCallClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onCallMeClick = this.onCallMeClick.bind(this);


	}

	initCrisp() {
		if (!window.$crisp) {
			setTimeout(() => {
				this.initCrisp();
			}, 500);

			return;
		}

		window.$crisp.push(["on", "chat:opened", () => {
			this.setState({
				is_form_hidden: true,
				is_form_visible: false
			})
		}]);

		window.$crisp.push(["on", "chat:closed", () => {
			this.setState({
				is_form_hidden: false
			})
		}]);
	}

	componentWillUnmount() {
		if (!window.$crisp) {
			return;
		}

		window.$crisp.push(["off", "chat:opened"]);
		window.$crisp.push(["off", "chat:closed"]);
	}

	state = {
		is_form_visible: false,
		is_form_hidden: false,
		phone: '',
		name: ''
	}

	onCallClick() {
		this.setState({
			is_form_visible: !this.state.is_form_visible
		});
	}

	onCallMeClick() {
		if (!window.$crisp) {
			return;
		}

		const {
			name,
			phone
		} = this.state;

		window.$crisp.push(["set", "user:phone", [phone]]);
		window.$crisp.push(["set", "user:nickname", [name]]);
		window.$crisp.push(["do", "message:send", ["text", "Hi! Please call me back. My number is " + phone]]);
		window.$crisp.push(["do", "chat:open"]);
		window.$crisp.push(["do", "message:show", ["text", "Hi! My name is Vitalii. Thank you for your request. I will call you as soon as possible. It usually takes up to 5 minutes."]]);

		setTimeout(() => {
			window.$crisp.push(["do", "message:show", ["text", "Meanwhile you can leave us your email or start typing question now:"]]);
		}, 5000);
		
		this.onCallClick();
	}

	onChange(prop, value) {
		this.setState({
			[prop]: value
		})
	}

	render() {
		const {
			is_form_visible,
			is_form_hidden,
			name
		} = this.state;

		return (
			<div className={cn("call-back-widget", {'_show': is_form_visible, '_hide': is_form_hidden})}>
		
				<a className='call'
					id='call_back_button'
					href='javascript:void(0);'
					onClick={this.onCallClick}
				/>
		
				<div className="call-back-container" id="call_back_container">
		
					<Input
						id="full_name"
						name="full_name"
						placeholder="Your name"
						onChange={(value) => this.onChange('name', value)}
						value={name}
						// value={full_name}
						// onChange={(value) => {
						// 	this.onChange('full_name', value)
						// }}
						// validationCallback={(is_error) => {
						// 	this.setValidationState('full_name', is_error)
						// }}
						// onBlur={() => {}}
						// validate={validate}
					/>
		
					<IntlTelInput
						containerClassName={cn(
							"intl-tel-input",
							// { validation_error: this.state.validationErrors.phone && this.state.validate }
						)}
						inputClassName="form-control"
						separateDialCode={true}
						onPhoneNumberChange={(status, value, country) => {
							// this.setValidationState('phone', !status)
							this.onChange('phone', country.dialCode + value)
						}}
						// value={phone}
						preferredCountries={['at', 'cz', 'sk', 'ua']}
						defaultCountry={'at'}
					/>
		
					<button className="btn btn-red" onClick={this.onCallMeClick}>CALL ME</button>
		
				</div>
			</div>
		);
	}
}

export default CallBack;

