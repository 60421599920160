import React, { Component } from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import './styles/styles.css';

import {
	Route,
	BrowserRouter,
	Switch
} from "react-router-dom";

import {
	LocalizeProvider,
	withLocalize
} from "react-localize-redux";

import isTouchDevice from "is-touch-device";
import cn from "classnames";

import Main from "./views/main";
import Content from "./views/content";

class App extends Component {
	componentDidMount () {
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = "ed03d0ef-8a26-4fe4-922e-ec7209d495f2";

		(function() {
			var d = document;
			var s = d.createElement("script");

			s.src = "https://client.crisp.chat/l.js";
			s.async = 1;
			d.getElementsByTagName("head")[0].appendChild(s);
		})();

		// let div = document.createElement('div');
		// div.innerHTML = "<a class='call' href='javascript:void(0);' onclick='$crisp.push([\"do\", \"message:show\", [\"text\", \"Please provide your phone number:\"]])' />";
		// div.innerHTML = "<a class='call' id='call_back_button' href='javascript:void(0);' onclick='$crisp.push([\"do\", \"message:show\", [\"text\", \"Can I help?\"]])' />";
		// div.innerHTML = "<a class='call' id='call_back_button' href='javascript:void(0);' onclick='callBack();' />";


		// setTimeout(function () {
		// 	document.body.append(div);
		// 	// $crisp.push(["do", "message:show", ["text", "Can I help you?"]]);
		// }, 5000);

		// function callBack() {
		// 	// $crisp.push(["set", "user:phone", ["0123456789"]])
		// 	// $crisp.push(["do", "message:show", ["text", "Can I help?"]])
		//
		// 	var call_back_container = document.getElementById('call_back_container');
		//
		// 	call_back_container.classList.toggle('_show');
		//
		// }

	};

	render() {
		return (
			<LocalizeProvider>
				<BrowserRouter>
					<div className={cn(
						"App",
						{touch: isTouchDevice()}
					)}>
						<Switch>
							<Route
								exact
								path="/"
								component={Main}
								language={this.props.languages}
							/>
							<Route
								render={() => <Content />}
							/>
						</Switch>
					</div>
				</BrowserRouter>
			</LocalizeProvider>
		);
	}
}

export default withLocalize(App);
