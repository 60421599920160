import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactSelect from 'react-select';
import * as CustomItem from './custom_list_items';
import { Textbox as InputValidated } from 'react-inputs-validation';
import servicesAvailability from './services_availability.json';
import Tooltip from '@material-ui/core/Tooltip';

const Help = ({children}) => (
	<Tooltip title={children} >
		<a href="javascript:void(0);" className="help-link" >i</a>
	</Tooltip>
);

class Input extends Component {
	render() {
		const {
			id,
			name,
			title,
			help_text,
			placeholder,
			data,
			setProp
		} = this.props;

		const onChange = (e) => {
			setProp((name || id), e.target.value);
		};

		return (
			<div className="form-item select">
				<label htmlFor={`${name}_input`}>
					{title}
					{help_text &&
						<Help>{help_text}</Help>
					}
				</label>
				<input
					type="text"
					name={name}
					defaultValue={data[name]}
					id={`${name}_input`}
					onChange={onChange}
					placeholder={placeholder}
					autoComplete="off"
				/>
			</div>
		)
	}
}

class Select extends Component {
	render() {
		const {id, name, title, options, selectedIndex = 0, help_text, setProp} = this.props;

		const onChange = (option) => {
			setProp((name || id), option.value, option.label);
		};

		return (
			<div className="form-item select">
				<label htmlFor={id}>
					{title}
					{help_text &&
						<Help>{help_text}</Help>
					}
				</label>
				<ReactSelect
					defaultValue={options[selectedIndex]}
					options={options}
					onChange={onChange}
					isSearchable={false}
				/>
			</div>
		)
	}
}

const servicesCheckboxesToggler = (selected_services, service) => {
	if (service === 'project_management' &&
		selected_services.indexOf('control_and_monitoring') >= 0
	) {
		selected_services.splice(
			selected_services.indexOf('control_and_monitoring'), 1
		);
	}

	if (service === 'control_and_monitoring' &&
		selected_services.indexOf('project_management') >= 0
	) {
		selected_services.splice(
			selected_services.indexOf('project_management'), 1
		);
	}

	if (service === 'technical_supervision' &&
		selected_services.indexOf('quality_assurance') >= 0
	) {
		selected_services.splice(
			selected_services.indexOf('quality_assurance'), 1
		);
	}

	if (service === 'quality_assurance' &&
		selected_services.indexOf('technical_supervision') >= 0
	) {
		selected_services.splice(
			selected_services.indexOf('technical_supervision'), 1
		);
	}
}

class Checkboxes extends Component {
	render() {
		const {id, name, options, data, setProp} = this.props;

		const getValue = (data, name) => (
			data[name] || []
		);

		const isSomeItemChecked = (data, name, option) => {
			const values = (option.options || []).map(option => option.value);

			return values.some(value => (
				data[name].indexOf(value) !== -1
			));
		};

		const isValueChecked = (data, name, option) => (
			getValue(data, name).indexOf(option.value) !== -1 || isSomeItemChecked(data, name, option)
		);

		const deleteArrayItems = (array, items) => {
			const result = array.slice(0);

			items.forEach(item => {
				const value_index = result.indexOf(item);

				if (value_index >= 0) {
					result.splice(value_index, 1);
				}
			});

			return result;
		};

		const onChange = (e, option) => {
			if (e.target.value.length) {
				const value = getValue(data, name).slice(0);

				const value_index = value.indexOf(e.target.value);

				if (value_index >= 0) {
					value.splice(value_index, 1);
				} else {
					value.push(e.target.value);

					servicesCheckboxesToggler(value, e.target.value);
				}

				setProp(name || id, value);
			} else {
				if (e.target.checked) {
					const result = getValue(data, name).slice(0);

					result.push(option.options[0].value);

					setProp(name || id, result);
				} else {
					const result = deleteArrayItems(
						getValue(data, name),
						option.options.map(option => option.value)
					);

					setProp(name || id, result);
				}
			}
		};

		return (
			<div>
				{options.map((option, index) => (
					<div className="list-item" key={`${option.value}_${index}`}>
						<div className="custom-checkbox">
							<input
								type="checkbox"
								defaultChecked={isValueChecked(data, name, option)}
								checked={isValueChecked(data, name, option)}
								name={name}
								value={option.value}
								id={`${option.value}_${index}`}
								onChange={e => onChange(e, option)}
							/>
							<label htmlFor={`${option.value}_${index}`}>
								{option.title}
							</label>
						</div>
						{option.help_text &&
							<Help>{option.help_text}</Help>
						}
						{option.type === 'radio' ? (
							<ListItem
								{...this.props}
								{...option}
								name={name}
								el_name={option.name}
							/>
						) : ''}
					</div>
				))}
			</div>
		)
	}
}

class Radios extends Component {
	constructor(props) {
		super(props);
		// create a ref to store the textInput DOM element
		this.checkboxForTextInput = React.createRef();
		this.textInput = React.createRef();
	}

	render() {
		const {name, el_name, options, data = {}, setProp} = this.props;

		const getValue = (data, name) => (
			data[name] || []
		);

		const isValueChecked = (data, name, value) => {
			return getValue(data, name).indexOf(value) !== -1
		};

		const deleteArrayItems = (array, items) => {
			const result = array.slice(0);

			items.forEach(item => {
				const value_index = result.indexOf(item);

				if (value_index >= 0) {
					result.splice(value_index, 1);
				}
			});

			return result;
		};

		const onChange = (e) => {
			if (data[name] instanceof Array) {
				const data_values = getValue(data, name).slice(0);
				const options_names = options.map(option => option.value);
				const result = deleteArrayItems(data_values, options_names);

				result.push(e.target.value);

				setProp(name, result);
			} else {
				if (name + '_custom' === e.target.name) {
					setProp(e.target.name, e.target.value);
				} else {
					setProp(name, e.target.value);
				}
			}

			if (name === 'stage') {
				setProp('services', []);
			}
		};

		return (
			<div>
				{options.map((option, index) => (
					<div className={`list-item `} key={`${option.value}_${index}`}>
						<div className="custom-radio">
							<input
								ref={this.checkboxForTextInput}
								type="radio"
								name={el_name || name}
								defaultChecked={isValueChecked(data, name, option.value)}
								checked={isValueChecked(data, name, option.value)}
								value={option.value}
								id={`${option.value}_${index}`}
								onChange={(e) => {
									onChange(e);
								}}
								onClick={() => {
									if (option.type === 'input') {
										this.textInput.current.focus();
									}
								}}
							/>
							<label htmlFor={`${option.value}_${index}`}>
								{option.title}
							</label>
						</div>
						{option.help_text &&
							<Help>{option.help_text}</Help>
						}
						{option.type === 'input' &&
							<input
								id={`${option.value}_${index}_input`}
								ref={this.textInput}
								type={`text`}
								name={`${name}_custom`}
								onChange={onChange}
								onFocus={() => {
									//document.getElementById(`${option.value}_${index}`).focus();
									this.checkboxForTextInput.current.click();
								}}
								onBlur={() => {}}
							/>
						}
					</div>
				))}
			</div>
		)
	}
}

const ApproachPopupGreen = ({onClose}) => (
	<div className="approach-popup _green-line _show">
		<a href="#" className="approach-popup-close" onClick={onClose} />
		<div className="approach-popup-title">Optimising costs over the whole life cycle</div>
		<div className="approach-popup-content">
			With DELTA green line the focus is on sustainability with regard to the environmental, social and functional dimensions that ideally complement the economic aspect. Here it is the individual, the environment and the life cycle of the building that come to the fore. The long-term stable value of the building is ensured by the project being oriented towards the optimization of life cycle costs over the whole useful life of the building.
		</div>
		<div className="approach-popup-icon" />
	</div>
);

const ApproachPopupRed = ({onClose}) => (
	<div className="approach-popup _red-line _show">
		<a href="#" className="approach-popup-close" onClick={onClose} />
		<div className="approach-popup-title">Optimising construction costs</div>
		<div className="approach-popup-content">
			With DELTA red line the focus is primarily on the cost-effectiveness of the building project. This involves the perfecting of the entire construction process from project implementation to hand-over of the property. From the use of construction-specific software like Datenpool , through the completion of all core services of design and build, architecture and construction management  to legal support during the contract – DELTA provides you with the best possible expertise in all specialist areas.
		</div>
		<div className="approach-popup-icon" />
	</div>
);

class ApproachRadios extends Component {
	constructor(props) {
		super(props);

		this.onPopupClose = this.onPopupClose.bind(this);
	}

	state = {
		popup: null
	};

	onPopupClose() {
		this.setState({
			popup: null
		});
	}

	showPopup(type) {
		this.setState({
			popup: type
		});
	}

	render() {
		const {
			id,
			title,
			name,
			options,
			value,
			setProp
		} = this.props;

		const {
			popup
		} = this.state;

		const onChange = (e) => {
			setProp(name, e.target.value);
		};

		if (popup === 'green') {
			return <ApproachPopupGreen onClose={this.onPopupClose} />
		}

		if (popup === 'red') {
			return <ApproachPopupRed onClose={this.onPopupClose} />
		}

		return (
			<div>
				<div>{title}</div>

				{options.map((option, index) => (
					<div className="list-item" key={`question_${id}_${index}`}>
						<div className="custom-radio _approach">
							<input
								type="radio"
								name={name}
								id={`question_${id}_${index}`}
								value={option.value}
								defaultChecked={(
									(!value && index === 0) || option.value === value
								)}
								onChange={onChange}
							/>
							<label htmlFor={`question_${id}_${index}`} />
							<div className="approach-row">
								<label htmlFor={`question_${id}_${index}`} >{option.title}</label>
								<div className="link-tip ">
									<a href="javascript:void(0);" className="link"
									   onClick={() => this.showPopup(option.value)}
									>LEARN MORE</a>
								</div>
							</div>
							<div className="description">
								{option.description}
							</div>
						</div>
					</div>
				))}
			</div>
		)
	}
}

class ListItem extends Component {
	getOptions() {
		const {
			name,
			options,
			data = {}
		} = this.props;
		
		const services_list = servicesAvailability[data.stage];

		const isVisible = (value) => {
			return services_list.indexOf(value) >= 0;
		}

		return name === 'services' ? 
			options.filter(option => {

				if (!services_list) {
					return true;
				}

				if (isVisible(option.name) ||
					isVisible(option.value)
				) {
					return true;
				}

				return false;
			}) :
			options
	};

	render() {
		const {
			name,
			type,
			options,
			component
		} = this.props;

		const props = {
			...this.props,
			options: this.getOptions()
		};

		return (
			component ? (
				CustomItem.get(component, props)
			) : type === 'radio' ? (
				<div className={`list-radio`}>
					<Radios {...props} />
				</div>
			) : type === 'approach_radio' ? (
				<ApproachRadios {...props} />
			) : type === 'checkbox' ? (
				<Checkboxes {...props} />
			) : typeof options !== 'undefined' ? (
				<Select {...props} />
			) : (
				<Input {...props} />
			)
		)
	}
}

export default ListItem;