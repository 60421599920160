import React, { Component } from 'react';

class SavePopup extends Component {
	render() {
		const {
			url,
			is_copied,
			onClose
		} = this.props;

		if (!url) {
			return (
				<div className="popup popup-save">
					<div className="popup-window">
						<a href="#" className="popup-close" onClick={onClose}/>
						<div className="popup-title">Saving...</div>
					</div>
				</div>
			)
		}

		return (
			<div className="popup popup-save">
				<div className="popup-window">
					<a href="#" className="popup-close" onClick={onClose}/>
					<div className="popup-icon _saved"/>

					<div className="popup-title">Your Project Saved</div>
					<div className="popup-subtitle">
						Use the link below to access your configuration any time.
					</div>

					<div className="direct-link">
						<div className="direct-link-title">DIRECT LINK:</div>

						<a href={url}
						   className="direct-link-anchor"
						>
							{url}
						</a>
						<a href="#" className="direct-link-copy"/>
					</div>

					<div className="popup-success">link copied to clipboard</div>

					<a href="#" className="send-link">Send this link to my email</a>
				</div>
			</div>
		);
	}
}

export default SavePopup;