import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router-dom";
import $ from 'jquery';
import cn from 'classnames';
import * as Actions from '../actions';
import ConfigMenu from "../components/configurator/config_menu";
import CallBack from "../components/call_back";
import Views from "./views";
import ViewsJson from "../components/configurator/views.json";
import ViewsOptions from "../components/configurator/view_options.json";
import EstimatedCost from "./estimated_cost.jsx";
import TopBar from "../components/top_menu/top_bar";

const Illustration = ({onClick, onMouseEnter, onMouseLeave, step, building_type}) => (
	<div className={cn('illustration-container', building_type)}>
		<div className={`illustration-wrapper`} >
			<div className={`illustration step-${step + 1}`} />

			{step === 0 &&
			<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				 viewBox="0 0 2629.8 1750">
				{/*1532.3*/}
				{/* Agriculture */}
				<g id="agriculture-group"
				   className="agriculture-group building-group"
				   data-type="agro"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-agriculture">
						<path className="st0" d="M1150.9,514.6c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3c0-4.7,3.2-6.4,7-4
				C1147.7,504.6,1150.9,510.1,1150.9,514.6z"/>
						<path className="st0"
							  d="M1148.6,540.1c0,3-2.1,4.3-4.7,2.8s-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8S1148.6,537.1,1148.6,540.1z"/>
						<path className="st0" d="M1146.6,562.6c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5
				C1145.4,558.8,1146.6,560.9,1146.6,562.6z"/>
						<path className="st1" d="M1176,467.8c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
				C1160.7,420.7,1176,446.6,1176,467.8z"/>
					</g>
					<g id="icon-agriculture">
						<path className="sticon" d="M1141.7,476.8c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-1.2-0.5-2.5-0.9-3.9c-0.6-0.4-1.2-0.8-1.8-1.2
				c-7.6-4.7-10.7-10.4-11.1-20c-0.1-2.1-0.1-4,2.2-3c-2.2-5.1-2.6-7.4-2.8-12.4c-0.1-2.1,0.2-3.3,1.8-2.4c-0.7-3.2-1.8-6.4-2.2-9.5
				c-0.7-6.5,0.1-6.8,5.3-3.3c3.4,2.4,6.2,5.6,8,10.3c2.8-4.3,5.7-5,10.9-3.2c1.6,0.6,2.2,1.5,2.4,3.6c0.3,4.3-0.1,8.1-2.1,11.2
				c2.3,0.6,2.6,2.1,2.7,4.2c0.2,4.1-0.2,7.7-2.2,10.7c2.4,0.5,2.6,2.3,2.7,4.2c0,1.1,0.1,2.3,0.1,3.4c-0.3,5.3-2,8.7-6.2,8.9
				c-1.8,0.1-3.8-0.2-5.5-0.3C1142,474.8,1141.9,475.8,1141.7,476.8z M1128,421.4c-1,7.8,4.3,16.9,10.7,18.4
				C1139.6,431.6,1134.6,423,1128,421.4z M1141,440.8c7,3,11.2-0.8,9.6-10.4C1143.6,427.2,1139.7,431.4,1141,440.8z M1139.8,469.7
				c1.4-7.6-4.1-17-10.7-18.4C1128.3,459.3,1133.2,467.7,1139.8,469.7z M1151.9,460.3c-7.1-2.9-11.2,0.2-9.6,10.4
				C1148.9,474.1,1153.3,469.4,1151.9,460.3z M1139.3,454.9c0.9-8.4-4.3-17.2-10.8-18.5C1127.9,444.5,1132.3,452.2,1139.3,454.9z
				 M1141.7,455.8c6.7,3.3,11.3-1.7,9.6-10.4C1144.2,442.2,1140.1,445.9,1141.7,455.8z"/>
					</g>
					<polygon
						id="agriculture-polygon"
						className="agriculture-polygon building-polygon st2"
						points="1396.7,993.7 934,1263.4 1379.9,1520.8 2160.3,1064.6 1389.2,617.3 1389.2,573.8 1322.6,535.3 1311.4,541.7 1267,516 1183.7,564 1144.4,541.1 1102.4,565.3 1102.4,571.4 1108.7,575 1100.3,580.2 1100.3,689.2 1211.4,753.6 1211.5,886.4 	"
					/>
					<g className="building-caption _flip">
						<g>
							<g>
								<path className="st3" d="M1275.6,1288.8c10.8-6.2,24.3-5.9,32.8-0.9l28.8,16.7l-6.2,3.6l-5.4-3.1c1.2,2.8-1.3,8.2-8.6,12.4
						c-10.3,5.9-22.3,6.2-30.1,1.6c-8.4-4.9-6.6-11.8,3.7-17.8c6.9-4,14.4-5,19.4-4.9l-8-4.7c-5.9-3.4-14.4-3.8-21.4,0.2
						c-5.5,3.2-6.6,6.7-4.4,10.1l-6,3.4C1265.2,1299.9,1267,1293.8,1275.6,1288.8z M1314.2,1314.2c6.8-3.9,9.4-10,3.8-13.3
						c-5.4-3.1-15.7-1.6-22.2,2.2c-7.2,4.2-8.7,8.9-2.7,12.4C1299.1,1319,1307.5,1318.1,1314.2,1314.2"/>
							</g>
							<g>
								<path className="st3" d="M1337.7,1254.1l6.2-3.6l43.3,25.2c9,5.2,7.6,13.9-3.5,20.2c-8.7,5-20.5,7.2-30,4.5l6.4-3.7
						c6.5,1,12.6-0.3,18.5-3.7c7.6-4.4,8.5-9.8,1.9-13.7l-9.8-5.7c2.6,3.1,0.9,9-6.4,13.2c-11.3,6.5-25.8,5.6-35.5,0l-7.2-4.2
						c-9.8-5.7-11.3-14,0.2-20.6c8.2-4.7,17.4-5,23-3.8L1337.7,1254.1z M1360.6,1267.7l-5.6-3.2c-7.4-4.3-19-4.5-26.5-0.2
						c-8.5,4.9-8.3,10.2,0,15l6.2,3.6c8.1,4.7,17.3,5,25.6,0.2C1367.8,1278.8,1368.8,1272.5,1360.6,1267.7"/>
							</g>
							<g>
								<path className="st3" d="M1425,1202.5c11-6.3,25.2-6.3,34.9-1.3l-5.8,3.3c-6.7-2.9-15.7-3.9-23.9,0.9c-8.9,5.2-7.4,10.9,0.8,15.6
						l7.4,4.3c8.1,4.7,18,5.7,27,0.5c8.3-4.8,6.6-10,1.6-13.9l5.8-3.3c8.6,5.6,8.6,13.9-2.4,20.2c-12.1,7-27.4,6.4-38.4,0.1l-7.3-4.2
						C1413.8,1218.4,1413,1209.5,1425,1202.5z"/>
							</g>
							<g>
								<polygon className="st3" points="1431.5,1250.1 1388.2,1225 1394.7,1221.2 1437.9,1246.4 				"/>
							</g>
							<g>
								<path className="st3" d="M1378.7,1230.1l6,3.5l-1.3,0.7c-8.3,4.8-8.1,11,2.8,17.3l21.2,12.3l-6.5,3.7l-43.3-25.2l6-3.5l10,5.8
						c-5.1-4.8-3.9-9.6,4-14.2L1378.7,1230.1z"/>
							</g>
							<g>

								<ellipse transform="matrix(0.9992 -4.026943e-02 4.026943e-02 0.9992 -47.867 56.5584)" className="st3" cx="1380" cy="1216.5" rx="5.6" ry="3.3"/>
							</g>
							<g>
								<path className="st3" d="M1492.4,1164.8l43.3,25.2l-6.1,3.5l-7.4-4.3c2.9,4,1.4,8.9-6.2,13.3c-9.5,5.5-22.2,5.2-32.6-0.9l-27.3-15.9
						l6.5-3.7l26.4,15.3c7.4,4.3,15.7,5.4,23.2,1.1c7.2-4.1,8.1-9.9-0.9-15.1l-25.5-14.8L1492.4,1164.8z"/>
							</g>
							<g>
								<path className="st3" d="M1524.9,1131.6l12.4,7.2l11.2-6.4l5.2,3l-11.2,6.4l26.6,15.4c4.4,2.6,7.9,2.7,12.7-0.1l4.3-2.5l5.2,3
						l-5.4,3.1c-8.3,4.8-16,4.4-23.3,0.1l-26.6-15.4l-7.4,4.3l-5.2-3l7.4-4.3l-12.4-7.2L1524.9,1131.6z"/>
							</g>
							<g>
								<polygon className="st3" points="1494.6,1142.2 1556.4,1178 1549.9,1181.8 1488.2,1145.9 				"/>
							</g>
							<g>
								<path className="st3" d="M1594.9,1105.6l43.3,25.2l-6.1,3.5l-7.4-4.3c2.9,4,1.4,8.9-6.2,13.3c-9.5,5.5-22.2,5.2-32.6-0.9l-27.3-15.9
						l6.5-3.7l26.4,15.3c7.4,4.3,15.7,5.4,23.2,1.1c7.2-4.1,8.1-9.9-0.9-15.1l-25.5-14.8L1594.9,1105.6z"/>
							</g>
							<g>
								<path className="st3" d="M1698.3,1074l-31.2,18l1.5,0.9c7.5,4.4,17.5,5.7,26.6,0.4c6.6-3.8,7.1-8.1,3.6-11.8l5.8-3.3
						c6.3,5.4,4.6,12.7-4.5,18c-11.3,6.5-26.4,6.9-38.1,0.1l-6.9-4c-11.8-6.8-12.8-15.2-0.6-22.3c12.4-7.2,26.7-5.9,37.3,0.3
						L1698.3,1074z M1660.8,1088.4l1.4,0.8l25.2-14.5l-2-1.2c-7.7-4.5-17.4-5.6-25.9-0.6C1650.1,1078.3,1652.9,1083.8,1660.8,1088.4"
								/>
							</g>
							<g>
								<path className="st3" d="M1629.7,1085.2l6,3.5l-1.3,0.7c-8.3,4.8-8.1,11,2.8,17.3l21.2,12.3l-6.5,3.7l-43.3-25.2l6-3.5l10,5.8
						c-5.1-4.8-3.9-9.6,4-14.2L1629.7,1085.2z"/>
							</g>
						</g>
					</g>
				</g>

				{/* Other */}
				<g id="other-group"
				   className="other-group building-group"
				   data-type="other"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<path
						id="other-polygon"
						className="other-polygon building-polygon st2"
						d="M1014.3,686.4l5.8"
					/>

				</g>

				{/* Culture & Education */}
				<g id="education-group"
				   className="education-group building-group"
				   data-type="culture_and_education"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-education">
						<path className="st0"
							  d="M2218,696.6c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3s3.2-6.4,7-4C2214.8,686.6,2218,692.1,2218,696.6z"/>
						<path className="st0" d="M2215.7,722.1c0,3-2.1,4.3-4.7,2.8c-2.6-1.5-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8
				C2213.6,715.3,2215.7,719.1,2215.7,722.1z"/>
						<path className="st0" d="M2213.7,744.6c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5
				C2212.5,740.8,2213.7,742.9,2213.7,744.6z"/>
						<path className="st1" d="M2243.1,649.8c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
				C2227.8,602.7,2243.1,628.6,2243.1,649.8z"/>
					</g>
					<g id="icon-education">
						<g>
							<path className="sticon" d="M2198.2,623.9c0.7,0,1.4-0.1,2-0.1c0.7,0,1.5-0.1,2.2-0.1c1.3,0,2.7-0.1,4-0.1c0.9,0,1.9,0.1,2.8,0.1
					c0.5,0,0.7-0.4,0.7-1c0-0.6-0.7-1.6-1.1-1.7c-0.4-0.1-0.8-0.2-1.1-0.2c-1.6,0-3.3,0-4.9,0c-1.2,0-2.5,0.1-3.7,0.2
					c-1.1,0.1-2.2,0-3.2,0.4c-1,0.4-1.6,1.4-1.8,2.9c-0.1,0.9-0.1,1.8-0.2,2.7c0,0.3,0.1,0.5,0.3,0.8c1.2,1.6,1.4,3.8,0.5,4.4
					c-0.1,0.1-0.1,0.2-0.1,0.4c0.5,2.3,0.8,4.6,0.9,6.8c0.1,1.3-0.1,2.3-0.4,3.2c-0.3,0.6-0.6,1.1-1.1,1.4c-0.6,0.4-1.4,0.1-2.2-0.7
					c-0.8-0.8-1.5-1.6-2.2-2.5c-0.4-0.5-0.4-0.6-0.1-0.9c0.4-0.4,0.8-0.8,1.2-1.3c0.8-1,1.3-2.2,1.6-3.8c0.1-1,0.2-2.1,0.2-3.1
					c0-0.1-0.1-0.3-0.2-0.4c-1.4-1.5-1.9-4.1-0.7-4.8c0.5-0.3,0.4-1,0.4-1.6c0-1.2,0.1-2.4,0.4-3.3c0.2-0.7,0.5-1.3,0.8-1.9
					c0,0,0-0.1,0.1-0.2c-0.8-0.7-1.5-1.3-2.3-2c-0.8-0.7-1.6-1.4-2.4-2.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.8-0.4-1.6,0.2-1.6
					c1.1,0,2.1,0.1,3.2,0.1c1.7,0.1,3.5,0.2,5.2,0.3c1.2,0.1,2.3,0.2,3.5,0.2c1,0.1,1.9,0.1,2.9,0.1c1.6,0.1,3.2,0.2,4.7,0.2
					c0.6,0,1.1,0.4,1.6,0.9c1.5,1.3,2.9,2.6,4.4,3.9c1.6,1.4,3.1,2.7,4.7,4.1c1.3,1.1,2.6,2.3,3.9,3.4c1.3,1.2,2.6,2.3,4,3.5
					c1.2,1.1,2.5,2.1,3.7,3.2c0.2,0.2,0.4,0.3,0.5,0.6c0.5,0.8,0.5,1.7,0,1.7c-0.5,0-1.1,0-1.6,0c-0.6,0-1.1,0-1.7-0.1
					c-1.1-0.1-2.3-0.1-3.4-0.2c-0.8,0-1.6,0-2.4-0.1c-1.1-0.1-2.3-0.2-3.4-0.2c-0.8,0-1.6,0-2.4-0.1c-0.9,0-1.7-0.1-2.6-0.2
					c-0.7,0-1.5-0.1-2.2-0.1c-0.4,0-0.8-0.3-1.2-0.6c-1.2-1.1-2.5-2.2-3.7-3.3c-2.1-1.9-4.2-3.7-6.3-5.6
					C2199,624.6,2198.6,624.3,2198.2,623.9z"/>
							<path className="sticon" d="M2196.5,625.3c1,0.9,2,1.8,3.1,2.7c1.6,1.4,3.2,2.8,4.8,4.2c1.7,1.5,3.4,3,5.1,4.5c0.8,0.8,1.6,0.9,2.3,0.9
					c1.2,0,2.5,0.1,3.7,0.2c1.8,0.1,3.6,0.2,5.3,0.3c0.9,0,1.9,0.1,2.8,0.2c0,0.2,0,0.3,0.1,0.5c0.2,2.4,0.4,4.7,0.7,7.1
					c0.1,1.5-0.3,2.4-1.2,2.8c-1,0.5-2.1,0.7-3.4,0.6c-0.8-0.1-1.7-0.2-2.5-0.4c-0.9-0.2-1.7-0.4-2.6-0.8c-1.2-0.4-2.4-0.9-3.6-1.5
					c-1.5-0.7-2.9-1.5-4.4-2.5c-1-0.7-2-1.5-3.1-2.2c-1-0.7-2-1.6-3-2.6c-0.8-0.8-1.5-1.7-2.2-2.6c-0.2-0.3-0.4-0.6-0.6-0.9
					c-0.1-0.1,0-0.3,0-0.4c0.3-0.4,0.4-0.9,0.5-1.5c0.1-0.9-0.2-1.8-0.5-2.8c-0.3-0.9-0.8-1.7-1.3-2.4c-0.1-0.1-0.2-0.2-0.2-0.3
					C2196.4,627.2,2196.3,626.2,2196.5,625.3z"/>
						</g>
					</g>
					<polygon
						id="education-polygon"
						className="education-polygon building-polygon st2"
						points="1941.3,869.2 2221.1,1030.7 2600.9,811.5 2481.3,742.5 2481.3,681 2426.4,649.1 2419.5,653 2392.1,637.5 2392.1,613.5 2337.2,581.9 2214.1,629.2 2214.2,652.8 2207.1,657.1 2200.4,653.2 2060.1,774.3 2071.4,794.1"
					/>
					<g className="building-caption _flip">
						<g>
							<g>
								<path className="st3" d="M2068.3,841.3c7.3-4.2,16.8-4.2,23.3-0.9l-3.9,2.2c-4.5-1.9-10.5-2.6-16,0.6c-6,3.4-4.9,7.3,0.5,10.4l5,2.9
						c5.4,3.1,12,3.8,18,0.3c5.5-3.2,4.4-6.6,1.1-9.2l3.9-2.2c5.8,3.7,5.7,9.2-1.6,13.5c-8.1,4.7-18.3,4.3-25.6,0.1l-4.8-2.8
						C2060.8,851.9,2060.2,846,2068.3,841.3z"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M2250.5,755.7l-20.8,12l1,0.6c5,2.9,11.7,3.8,17.8,0.3c4.4-2.5,4.7-5.4,2.4-7.9l3.9-2.2
							c4.2,3.6,3.1,8.5-3,12c-7.5,4.4-17.6,4.6-25.4,0.1l-4.6-2.7c-7.8-4.6-8.5-10.2-0.4-14.9c8.3-4.8,17.8-3.9,24.9,0.2
							L2250.5,755.7z M2225.5,765.3l0.9,0.5l16.8-9.7l-1.4-0.8c-5.1-3-11.6-3.7-17.3-0.4C2218.3,758.5,2220.1,762.2,2225.5,765.3"/>
								</g>
								<g>
									<path className="st3" d="M2204.7,763.2l4,2.3l-0.8,0.5c-5.5,3.2-5.4,7.3,1.9,11.5l14.2,8.2l-4.3,2.5l-28.9-16.8l4-2.3l6.6,3.9
							c-3.4-3.2-2.6-6.4,2.7-9.4L2204.7,763.2z"/>
								</g>
								<g>
									<path className="st3" d="M2181.5,776.8l28.9,16.8l-4.1,2.4l-4.9-2.9c1.9,2.7,0.9,5.9-4.1,8.8c-6.4,3.7-14.8,3.4-21.7-0.6
							l-18.2-10.6l4.3-2.5l17.6,10.2c4.9,2.8,10.5,3.6,15.5,0.7c4.8-2.8,5.4-6.6-0.6-10.1l-17-9.9L2181.5,776.8z"/>
								</g>
							</g>
							<g>
								<path className="st3" d="M2134.8,794.1l8.3,4.8l7.4-4.3l3.5,2l-7.4,4.3l17.7,10.3c2.9,1.7,5.3,1.8,8.5-0.1l2.9-1.7l3.5,2l-3.6,2.1
						c-5.6,3.2-10.7,2.9-15.6,0.1l-17.7-10.3l-4.9,2.9l-3.5-2l4.9-2.9l-8.3-4.8L2134.8,794.1z"/>
							</g>
							<g>
								<polygon className="st3" points="2114.7,801.1 2155.8,825 2151.5,827.5 2110.3,803.6 				"/>
							</g>
							<g>
								<path className="st3" d="M2113.2,816.2L2142,833l-4.1,2.4l-4.9-2.9c1.9,2.7,0.9,5.9-4.1,8.8c-6.4,3.7-14.8,3.4-21.7-0.6l-18.2-10.6
						l4.3-2.5l17.6,10.2c4.9,2.8,10.5,3.6,15.5,0.7c4.8-2.8,5.4-6.6-0.6-10.1l-17-9.9L2113.2,816.2z"/>
							</g>
							<g>
								<path className="st3" d="M2176.1,884.3l9.3,0.1l-0.1,2.9l-8.5-0.1c-0.1,3.2-2.7,6.5-8.3,9.8c-7.9,4.6-19.5,5.5-27.2,1.1
						c-5.9-3.4-5.6-7.5-3-11.3l-0.5,0c-4.1-0.1-8.6-0.5-12-2.5c-5.5-3.2-4.8-7.5,1.6-11.2c5.8-3.3,13.9-3.9,19.2-0.8l2.2,1.3
						l-3.6,2.1l-2.1-1.2c-3.4-2-8.8-1.8-12.6,0.4c-4,2.3-4.8,5-1.3,7.1c2.7,1.5,5.9,1.9,9.7,2l31.8,0.3c-1-1.3-2.5-2.6-4.7-3.9
						l-4-2.3l3.6-2.1l4.1,2.4C2172.9,880.1,2175.1,882.2,2176.1,884.3z M2166.3,894.7c3.9-2.2,5.9-4.9,5.6-7.5l-29.2-0.3
						c-1.9,2.8-2.5,6,2.6,9C2151,899.2,2159.3,898.7,2166.3,894.7"/>
							</g>
							<g>
								<path className="st3" d="M2232.5,922.8l-20.8,12l1,0.6c5,2.9,11.7,3.8,17.8,0.3c4.4-2.5,4.7-5.4,2.4-7.9l3.9-2.2
						c4.2,3.6,3.1,8.5-3,12c-7.6,4.4-17.6,4.6-25.4,0.1l-4.6-2.7c-7.8-4.6-8.5-10.2-0.4-14.9c8.3-4.8,17.8-3.9,24.9,0.2L2232.5,922.8
						z M2207.5,932.4l0.9,0.5l16.8-9.7l-1.4-0.8c-5.1-3-11.6-3.7-17.2-0.4C2200.4,925.6,2202.2,929.3,2207.5,932.4"/>
							</g>
							<g>
								<path className="st3" d="M2436.3,785.6c6.5-3.8,15.1-3.6,22,0.4l18.2,10.6l-4.3,2.5l-17.6-10.2c-4.9-2.9-10.4-3.5-15.7-0.5
						c-4.8,2.7-5.5,6.5,0.5,10l17,9.9l-4.3,2.5l-28.9-16.8l4.1-2.4l4.9,2.8C2430.3,791.8,2431.2,788.6,2436.3,785.6z"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M2401.8,805.6c8.2-4.8,18.6-4.2,25.7-0.1l4.7,2.8c7.1,4.1,8,10.1-0.2,14.9c-8.3,4.8-18.6,4.2-25.7,0.1
							l-4.7-2.8C2394.4,816.3,2393.5,810.3,2401.8,805.6z M2428,810.6l-4.6-2.7c-5.5-3.2-12.4-3.7-18.2-0.4
							c-5.7,3.3-4.9,7.3,0.6,10.5l4.6,2.7c5.5,3.2,12.4,3.7,18.2,0.4C2434.3,817.8,2433.5,813.8,2428,810.6"/>
								</g>
								<g>
									<polygon className="st3" points="2406,837.4 2377.1,820.6 2381.4,818.1 2410.3,834.9 					"/>
								</g>
								<g>
									<path className="st3" d="M2354,824.3l8.3,4.8l7.4-4.3l3.5,2l-7.4,4.3l17.7,10.3c2.9,1.7,5.3,1.8,8.5-0.1l2.9-1.7l3.5,2l-3.6,2.1
							c-5.6,3.2-10.7,3-15.6,0.1l-17.7-10.3l-4.9,2.8l-3.5-2l4.9-2.8l-8.3-4.8L2354,824.3z"/>
								</g>
							</g>
							<g>
								<path className="st3" d="M2334.6,844.3c7.2-4.2,16.2-3.9,21.9-0.6l19.2,11.1l-4.1,2.4l-3.6-2.1c0.8,1.9-0.9,5.5-5.7,8.3
						c-6.9,4-14.8,4.1-20.1,1.1c-5.6-3.2-4.4-7.9,2.5-11.9c4.6-2.7,9.6-3.3,12.9-3.3l-5.4-3.1c-3.9-2.3-9.6-2.6-14.3,0.1
						c-3.7,2.1-4.4,4.5-2.9,6.7l-4,2.3C2327.7,851.7,2328.8,847.7,2334.6,844.3z M2360.3,861.3c4.5-2.6,6.3-6.7,2.5-8.8
						c-3.6-2.1-10.5-1.1-14.8,1.4c-4.8,2.8-5.8,6-1.8,8.3C2350.3,864.5,2355.9,863.9,2360.3,861.3"/>
							</g>
							<g>
								<path className="st3" d="M2301.9,863.2c7.3-4.2,16.8-4.2,23.3-0.9l-3.9,2.2c-4.5-1.9-10.4-2.6-16,0.6c-6,3.4-4.9,7.3,0.5,10.4l5,2.9
						c5.4,3.1,12,3.8,18,0.3c5.5-3.2,4.4-6.6,1.1-9.2l3.9-2.2c5.7,3.7,5.7,9.2-1.6,13.5c-8.1,4.7-18.3,4.3-25.6,0.1l-4.9-2.8
						C2294.5,873.8,2293.9,867.8,2301.9,863.2z"/>
							</g>
							<g>
								<path className="st3" d="M2282,875.5l28.9,16.8l-4.1,2.4l-4.9-2.8c1.9,2.7,0.9,5.9-4.1,8.8c-6.4,3.7-14.8,3.4-21.7-0.6l-18.2-10.6
						l4.3-2.5l17.6,10.2c4.9,2.9,10.5,3.6,15.5,0.7c4.8-2.8,5.4-6.6-0.6-10.1l-17-9.9L2282,875.5z"/>
							</g>
							<g>
								<path className="st3" d="M2232.3,890l4.3-2.5l41.2,23.9l-4.1,2.4l-4.5-2.6c1.4,2.3,0.2,5.9-4.6,8.7c-7.7,4.4-17.8,3.7-25.1-0.5
						l-4.4-2.6c-7.5-4.4-8.2-10.2-0.9-14.5c4.5-2.6,10-3.4,14.7-2.7L2232.3,890z M2260.7,906.5l-3.7-2.2c-5.9-3.4-13.7-3.2-18.5-0.4
						c-5.5,3.2-4.2,7.4,1.1,10.5l4.1,2.4c5.3,3.1,12.1,4.1,18.2,0.6C2267.5,914,2266.3,909.7,2260.7,906.5"/>
							</g>
							<g>
								<path className="st3" d="M2374.2,816.4c-1.6,1-3.8,1-5.3,0.1c-1.5-0.9-1.5-2.1,0.1-3.1c1.6-0.9,3.8-0.9,5.3-0.1
						C2375.9,814.2,2375.9,815.5,2374.2,816.4z"/>
							</g>
						</g>
					</g>
				</g>

				{/* Infrastructure */}
				<g id="infrastructure-group"
				   className="infrastructure-group building-group"
				   data-type="infrastructure"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-infrastructure">
						<path className="st0"
							  d="M1002.1,827c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3s3.2-6.4,7-4C998.9,817,1002.1,822.5,1002.1,827z"/>
						<path className="st0"
							  d="M999.8,852.5c0,3-2.1,4.3-4.7,2.8s-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8S999.8,849.5,999.8,852.5z"/>
						<path className="st0" d="M997.8,875c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5C996.6,871.2,997.8,873.3,997.8,875
				z"/>
						<path className="st1" d="M1027.2,780.2c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
				C1011.9,733.1,1027.2,759,1027.2,780.2z"/>
					</g>
					<g id="icon-infrastructure">
						<path className="sticon" d="M987.8,748.7c-3.1-1.7-6.2-3.4-9.2-5.1c0.1,1.7,0.1,3.3,0.2,4.9c0,1-0.3,1.6-0.9,1.6
				c-0.6,0.1-1.3-0.3-1.9-1.2c-0.5-0.7-0.8-1.5-0.8-2.3c-0.1-2.5-0.2-5.1-0.2-7.6c0-0.9,0.3-1.4,0.8-1.6c3.6-1.2,7.1-2.5,10.7-3.7
				c0.3-0.1,0.7,0,1,0.2c3.7,2,7.4,4.1,11.1,6.2c0.4,0.2,0.8,0.6,1.1,1.1c3.7,5.2,7.5,10.5,11.2,15.8c0.6,0.9,1,1.8,1,2.8
				c0.1,2.4,0.1,4.7,0.2,7.1c0,1.5-0.6,2.2-1.7,1.6c-1.1-0.6-1.9-2.1-1.9-3.6c-0.1-1.6-0.1-3.2-0.2-4.8c-3.1-1.7-6.1-3.4-9.2-5.1
				c0,1.3,0.1,2.6,0.1,3.9c-0.1,1.9,0.4,3.8,1.1,6c3,8.6,5.8,17.3,8.7,25.9c0.6,1.7,0.3,2.9-0.6,3.1c-0.9,0.2-2-0.9-2.5-2.3
				c-0.5-1.6-1.3-2.8-2.1-4c-3-4.2-6.1-8.4-9.1-12.6c-0.3-0.5-0.6-0.6-0.9-0.5c-3.2,1-6.4,2-9.5,3.1c-0.3,0.1-0.5,0.4-0.7,0.7
				c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,1-1.5,0.9-2.4-0.2c-0.9-1.1-1.4-2.8-0.9-3.8c0.8-1.8,1.6-3.7,2.4-5.5c1.9-4.4,3.8-8.9,5.7-13.3
				c0.1-0.2,0.1-0.3,0.1-0.5C987.9,753.2,987.8,751,987.8,748.7z M989.8,762.9c1.3,1.8,2.6,3.7,3.9,5.5c1.3-0.4,2.5-0.8,3.7-1.2
				c-0.4-1.3-0.8-2.5-1.3-3.8c-0.1-0.2-0.3-0.4-0.4-0.5c-1.5-0.9-3-1.7-4.5-2.5c-0.1-0.1-0.3,0-0.4,0.1
				C990.5,761.3,990.2,762.1,989.8,762.9z M994.9,747.8c-0.1-1.7-0.1-3.3-0.2-4.9c-1.2-0.7-2.4-1.4-3.7-2c0.1,1.7,0.1,3.3,0.2,4.9
				C992.5,746.5,993.7,747.2,994.9,747.8z M991.6,755.7c1.2,0.7,2.5,1.4,3.7,2c-0.1-1.7-0.1-3.3-0.2-4.9c-1.2-0.7-2.4-1.4-3.7-2
				C991.5,752.4,991.5,754,991.6,755.7z M987.4,739.3c-1.6,0.6-3.2,1.1-4.7,1.6c0,0,0,0.1,0,0.1c1.6,0.9,3.2,1.8,4.8,2.7
				C987.5,742.2,987.5,740.8,987.4,739.3z M998.5,745.4c0.1,1.6,0.1,3,0.1,4.4c1.6,0.9,3.2,1.8,5,2.8
				C1001.8,750.1,1000.2,747.8,998.5,745.4z M997.3,773.2c1.4,1.9,2.7,3.8,4.1,5.6c0,0,0,0,0.1-0.1c-0.7-2-1.4-4.1-2.1-6.2
				C998.6,772.7,998,772.9,997.3,773.2z M988.3,766.4c-0.6,1.4-1.2,2.7-1.8,4.1c0,0,0.1,0.1,0.1,0.1c1.3-0.4,2.5-0.8,3.8-1.2
				C989.8,768.4,989.1,767.4,988.3,766.4z"/>
					</g>
					<path id="infrastructure-polygon" className="infrastructure-polygon building-polygon st2" d="M479.9,1001.4l454.1,262l436.8-257l-162.8-92.6L1196,900l-42.5-23.5L1151,756l-55-32.7l-8.4-5.7l-83.2-49l-35.8,20h-10.4L938,668.3l-26.8-59.6l-22.3,40.2l-31.5,15.5l-5.5,20.6l-29.1,24.8l-35.2,13.5l-16.7-33.4L756,723.3l-39.9,24.8v19.1l-40.7,30.7l-40.7,23l-20.2-46.7l-17.8,41.1L556.8,840l-1.6,49l40.7,23.3v22.3L479.9,1001.4z"/>
					<g className="building-caption _flip">
						<g>
							<g>
								<path className="st3" d="M893.6,1068.2c7.2-4.2,16.2-3.9,21.9-0.6l19.2,11.1l-4.1,2.4l-3.6-2.1c0.8,1.9-0.9,5.5-5.7,8.3
						c-6.9,4-14.8,4.1-20.1,1.1c-5.6-3.2-4.4-7.9,2.5-11.9c4.6-2.7,9.6-3.3,12.9-3.3l-5.4-3.1c-3.9-2.3-9.6-2.6-14.3,0.1
						c-3.7,2.1-4.4,4.5-2.9,6.7l-4,2.3C886.7,1075.6,887.8,1071.5,893.6,1068.2z M919.3,1085.2c4.5-2.6,6.3-6.7,2.5-8.8
						c-3.6-2.1-10.5-1.1-14.8,1.4c-4.8,2.8-5.8,6-1.8,8.3C909.2,1088.4,914.8,1087.7,919.3,1085.2"/>
							</g>
							<g>
								<path className="st3" d="M844.2,1083.3l3.5,2l-3.7,2.2c-3.1,1.8-3,3.4,0.2,5.2l4.2,2.4l7.2-4.1l3.5,2l-7.2,4.1l25.4,14.7l-4.3,2.5
						l-25.4-14.7l-4.3,2.5l-3.5-2l4.3-2.5l-3.8-2.2c-5.6-3.2-6-6.4-0.1-9.8L844.2,1083.3z"/>
							</g>
							<g>
								<path className="st3" d="M875.9,1079l4,2.3l-0.9,0.5c-5.5,3.2-5.4,7.3,1.9,11.5l14.2,8.2l-4.3,2.5l-28.9-16.8l4-2.3l6.7,3.9
						c-3.4-3.2-2.6-6.4,2.6-9.4L875.9,1079z"/>
							</g>
							<g>
								<path className="st3" d="M821.7,1109.7c6.5-3.8,15.1-3.6,22,0.4l18.2,10.6l-4.3,2.5L840,1113c-4.9-2.9-10.4-3.5-15.7-0.5
						c-4.8,2.7-5.5,6.5,0.5,10l17,9.9l-4.3,2.5l-28.9-16.8l4.1-2.4l4.9,2.8C815.6,1115.9,816.6,1112.6,821.7,1109.7z"/>
							</g>
							<g>
								<polygon className="st3" points="823.6,1142.8 794.8,1126 799.1,1123.5 827.9,1140.3 				"/>
							</g>
							<g>
								<path className="st3" d="M791.9,1121.9c-1.6,1-3.8,1-5.3,0.1c-1.5-0.9-1.5-2.1,0.1-3.1c1.6-1,3.8-1,5.3-0.1
						C793.6,1119.7,793.6,1120.9,791.9,1121.9z"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M1009.6,1002l28.9,16.8l-4.1,2.4l-4.9-2.8c1.9,2.7,0.9,5.9-4.1,8.8c-6.4,3.7-14.8,3.4-21.7-0.6l-18.2-10.6
							l4.3-2.5l17.6,10.2c4.9,2.9,10.5,3.6,15.5,0.7c4.8-2.8,5.4-6.6-0.6-10.1l-17-9.9L1009.6,1002z"/>
								</g>
								<g>
									<path className="st3" d="M979.7,1019.1l4,2.3l-0.8,0.5c-5.5,3.2-5.4,7.3,1.9,11.5l14.2,8.2l-4.3,2.5l-28.9-16.8l4-2.3l6.7,3.9
							c-3.4-3.2-2.6-6.4,2.7-9.4L979.7,1019.1z"/>
								</g>
								<g>
									<path className="st3" d="M942.9,1030.9l8.3,4.8l7.4-4.3l3.5,2l-7.4,4.3l17.7,10.3c2.9,1.7,5.3,1.8,8.5-0.1l2.9-1.7l3.5,2l-3.6,2.1
							c-5.6,3.2-10.7,2.9-15.6,0.1l-17.7-10.3l-4.9,2.8l-3.5-2l4.9-2.8l-8.3-4.8L942.9,1030.9z"/>
								</g>
								<g>
									<path className="st3" d="M924.3,1050.4c6.1-3.5,14.5-4.1,19.8-1l0.3,0.2l-3.9,2.3l-0.2-0.1c-3.6-2.1-8.5-1.9-12.8,0.6
							c-4,2.3-5.3,5-2.1,6.9c2,1.1,4.2,1.3,6.8,0.4l11-3.9c5.6-2,10.4-2.2,14.6,0.2c5.1,3,3.9,8-3,12c-7,4.1-15.5,4.9-21.1,1.7
							l-0.3-0.2l3.9-2.3l0.2,0.1c3.6,2.1,9.4,1.5,14.1-1.2c4.5-2.6,6-5.5,2.3-7.6c-2-1.2-4.8-1.3-7.6-0.2l-10.6,3.8
							c-5.3,1.9-10.4,1.9-14.1-0.3C916.1,1058.4,917.9,1054.2,924.3,1050.4z"/>
								</g>
							</g>
							<g>
								<path className="st3" d="M1048.9,969.7l8.3,4.8l7.4-4.3l3.5,2l-7.4,4.3l17.7,10.3c2.9,1.7,5.3,1.8,8.5-0.1l2.9-1.7l3.5,2l-3.6,2.1
						c-5.6,3.2-10.7,2.9-15.6,0.1l-17.7-10.3l-4.9,2.8l-3.5-2l4.9-2.8l-8.3-4.8L1048.9,969.7z"/>
							</g>
							<g>
								<path className="st3" d="M1029.5,989.7c7.3-4.2,16.8-4.2,23.3-0.9l-3.9,2.2c-4.5-1.9-10.4-2.6-16,0.6c-6,3.4-4.9,7.3,0.5,10.4l5,2.9
						c5.4,3.1,12,3.8,18,0.3c5.5-3.2,4.4-6.7,1.1-9.2l3.9-2.2c5.8,3.7,5.7,9.2-1.6,13.5c-8.1,4.7-18.3,4.3-25.6,0.1l-4.9-2.8
						C1022.1,1000.3,1021.5,994.4,1029.5,989.7z"/>
							</g>
							<g>
								<path className="st3" d="M1095.6,952.4l28.9,16.8l-4.1,2.4l-4.9-2.8c1.9,2.7,0.9,5.9-4.1,8.8c-6.4,3.7-14.8,3.4-21.7-0.6l-18.2-10.6
						l4.3-2.5l17.6,10.2c4.9,2.9,10.5,3.6,15.5,0.7c4.8-2.8,5.4-6.6-0.6-10.1l-17-9.9L1095.6,952.4z"/>
							</g>
							<g>
								<path className="st3" d="M1164.6,931.3l-20.8,12l1,0.6c5,2.9,11.7,3.8,17.8,0.3c4.4-2.5,4.7-5.4,2.4-7.9l3.9-2.2
						c4.2,3.6,3.1,8.5-3,12c-7.6,4.4-17.6,4.6-25.4,0.1l-4.6-2.7c-7.8-4.6-8.5-10.2-0.4-14.9c8.3-4.8,17.8-3.9,24.9,0.2L1164.6,931.3
						z M1139.6,940.9l0.9,0.5l16.8-9.7l-1.4-0.8c-5.1-3-11.6-3.7-17.3-0.4C1132.4,934.1,1134.3,937.8,1139.6,940.9"/>
							</g>
							<g>
								<path className="st3" d="M1118.8,938.8l4,2.3l-0.8,0.5c-5.5,3.2-5.4,7.3,1.9,11.5l14.2,8.2l-4.3,2.5l-28.9-16.8l4-2.3l6.7,3.9
						c-3.4-3.2-2.6-6.4,2.7-9.4L1118.8,938.8z"/>
							</g>
						</g>
					</g>
				</g>

				{/* Shopping & Entertainment */}
				<g id="shopping-group"
				   className="shopping-group building-group"
				   data-type="shopping_and_entertainment"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-shopping">
						<path className="st0" d="M1505.9,348.5c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3c0-4.7,3.2-6.4,7-4
				C1502.7,338.5,1505.9,344,1505.9,348.5z"/>
						<path className="st0"
							  d="M1503.6,374c0,3-2.1,4.3-4.7,2.8s-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8S1503.6,371,1503.6,374z"/>
						<path className="st0" d="M1501.6,396.5c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5
				C1500.4,392.7,1501.6,394.8,1501.6,396.5z"/>
						<path className="st1" d="M1531,301.7c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
				C1515.7,254.6,1531,280.5,1531,301.7z"/>
					</g>
					<g id="icon-shopping">
						<path className="sticon" d="M1510.6,313.5c-8.6-4.5-17.2-9-25.9-13.5c-0.1-0.1-0.1-0.2-0.2-0.3
			c-2.2-1.9-3.3-4.6-3.2-7.3c0.1-1.3,0.1-2.8,0.1-4.1c0.1-3.8,0.2-7.5,0.3-11.3c0.1-3.9,0.2-7.9,0.3-11.8c0-3.4,1.3-4.5,4-3.1
			c0.9,0.5,1.9,1,2.9,1.5c-0.1-1.2-0.1-2.3-0.2-3.3c-0.2-3.8,1.3-6.1,4.1-5.9c0.7,0.1,1.4,0.3,2,0.5c0.1,0,0.1,0.1,0.2,0.1
			c0.5,0.3,0.9,0.6,1.4,1c3.2,2.7,5.5,7.2,5.8,11.6c0.1,1,0.1,2,0.2,3c0.9,0.5,1.7,0.9,2.6,1.3c2.1,1.1,3.6,3.5,4,6.3
			c0.6,3.7,1,7.4,1.5,11c0.8,6.1,1.7,12.1,2.5,18.2c0.2,1.2,0.4,2.4,0.3,3.4C1513.3,313.1,1512.2,313.7,1510.6,313.5z M1500.1,272.7
			c-2.9-1.5-5.7-3-8.6-4.5c0.1,1.4-0.1,2.5,1.1,4.1c1.2,1.6,1.4,3.7,0.8,5c-0.6,1.2-1.9,1.3-3.2,0.2c-1.4-1.2-2.3-2.8-2.6-4.7
			c-0.3-1.7,0.2-2.7,1.1-3.1c0.2-0.1,0.5-0.3,0.5-0.5c0-0.7-0.1-1.5-0.1-2.3c-1.3-0.7-2.5-1.3-3.7-1.9c-0.6-0.3-1,0.2-1,0.9
			c-0.1,3.6-0.2,7.3-0.3,10.9c-0.1,3.2-0.2,6.3-0.3,9.5c-0.1,2.7-0.2,5.3-0.2,8c0,1.5,0.5,2.3,1.6,2.9c4.3,2.2,8.6,4.5,12.9,6.7
			c3.8,2,7.6,4,11.4,5.9c0.9,0.5,1.3,0.1,1.2-1.1c-0.1-0.8-0.2-1.7-0.3-2.5c-0.5-3.4-0.9-6.9-1.4-10.3c-0.5-3.5-1-7-1.5-10.5
			c-0.4-2.7-0.7-5.3-1.1-8c-0.1-0.8-0.5-1.7-1.2-2c-0.9-0.6-1.9-1-2.8-1.5c-0.1,2,0,2.3,1,4.1c1.3,2.2,1.5,4.8,0.3,5.7
			c-1.1,0.9-3.1-0.1-4.3-2.3c-1.3-2.2-1.4-4.9-0.2-5.6c0.7-0.4,0.8-1.1,0.7-2.1C1500.2,273.4,1500.2,273.1,1500.1,272.7z
			 M1499.9,269.3c-0.1-1.1-0.1-2.2-0.2-3.2c-0.2-2.5-1.5-5.3-3.3-7c-1.8-1.7-3.7-1.8-4.7-0.3c-0.9,1.4-0.5,3.8-0.4,6
			C1494.3,266.4,1497,267.8,1499.9,269.3z M1490.6,272.8c-0.5-0.3-0.9,0.1-0.9,0.7c0,0.6,0.7,1.3,1,1.5c0.2,0.1,0.9,0.3,0.9-0.5
			C1491.6,274.2,1491.4,273.4,1490.6,272.8z M1502.7,280.3c-0.1-0.8-0.7-1.6-1-1.7c-0.2-0.1-0.9-0.1-0.9,0.7c0.2,0.9,0.7,1.3,1,1.5
			C1502.6,281.2,1502.7,280.4,1502.7,280.3z"/>
					</g>
					<polygon
						id="shopping-polygon"
						className="shopping-polygon building-polygon st2"
						points="1320.8,511.7 1518.2,625.8 1519.5,626.1 1521.5,626.7 1527.8,623.1
			1556.6,639.8 1557.2,641.3 1557.6,642.8 1557.8,643.9 1558.1,645.4 1558.3,646.7 1558.3,647.6 1558.4,649 1571.6,656.6
			1573.8,655.2 1574.5,654.9 1574.7,654.8 1575,654.7 1575.3,654.8 1575.7,654.9 1576,655.1 1576.5,655.3 1578.6,656.5 1578.9,656.7
			1579,656.9 1579,657.2 1579,657.6 1578.9,657.8 1578.6,658 1578.5,658.5 1578.3,659.1 1578.1,659.5 1577.8,659.8 1577.6,660
			1760,765.4 1762.4,764.3 1763.1,764.1 1763.3,764.1 1763.4,764.1 1768.6,767.3 1775.8,772.6 1781.6,777.9 1809.5,794 1814.1,791.2
			1814.2,791.1 1814.4,791.1 1814.5,791.1 1814.7,791 1814.9,791.1 1815.1,791.1 1815.3,791.2 1815.5,791.3 1817.8,792.5
			1818.2,792.7 1818.6,793 1818.7,793.1 1818.8,793.2 1818.8,793.4 1818.7,793.5 1818.7,793.9 1818.7,794 1818.6,794.1 1818.5,794.2
			1818.4,794.2 1818.3,794.3 1818.2,794.6 1818.1,795.1 1817.7,795.7 1817.4,796.1 1815.3,797.4 1880.4,835 2280.4,604.1 2178,544.6
			2178,495.2 1893,332.7 1876.2,342.4 1819.4,309.7 1819.4,302 1818.9,299.4 1818,296.8 1816.5,293.6 1814.4,290.6 1812.1,287.9
			1808.2,284 1802.6,279.8 1751,250.1 1742.6,246.6 1734.1,244.2 1725,244.4 1722.8,244.7 1720.4,245.3 1717.9,246.2 1715.9,247.3
			1714.4,248.4 1713.1,249.7 1711.8,251.5 1710.9,253.4 1702.7,283.6 1702.1,285.8 1701.9,287.9 1701.9,291.7 1655.2,318.7
			1651.2,318.4 1644.5,318.8 1637.4,319.8 1629.7,321.2 1623.3,322.9 1616.8,325 1610.3,327.7 1529.5,374.3 1503.7,359.4
			1431.9,400.9 1430.3,401.9 1429.2,403 1428.6,404.1 1426.4,412.5 1426.6,413.3 1427,414.2 1427.8,415.2 1428.8,415.8 1427.8,416.3
			1432.5,419.3 1433.7,418.6 1434.2,418.9 1434.2,420.8 1434.2,446.2"/>
					<g className="building-caption">
						<g>
							<g>
								<path className="st3" d="M1739.6,327.7c6.8,3.9,8,9.4,2,12.9l-0.3,0.2l-4.4-2.5l0.2-0.1c4-2.3,3.7-5.6-1.1-8.4
						c-4.5-2.6-9.8-3.4-13.4-1.3c-2.2,1.3-2.5,2.7-0.7,4.4l7.7,7.2c3.8,3.6,4.4,6.8-0.3,9.5c-5.8,3.4-15.6,2.5-23.2-1.9
						c-7.9-4.6-9.6-10.1-3.3-13.7l0.3-0.2l4.4,2.5l-0.2,0.1c-4.1,2.4-2.9,6.1,2.5,9.2c5,2.9,10.6,3.9,14.8,1.4c2.3-1.3,2.5-3.1,0.4-5
						l-7.5-6.9c-3.8-3.4-3.7-6.7,0.6-9.2C1724,322.4,1732.4,323.6,1739.6,327.7z"/>
							</g>
							<g>
								<path className="st3" d="M1851,392.1c8.7,5.1,7.4,11.5-0.9,16.3l-4.9,2.9c-8.4,4.9-19.8,5.4-28.1,0.6c-5.1-2.9-6.7-6.5-5.3-9.5
						l-15.9,9.2l-4.9-2.8l43.5-25.3l4.6,2.7l-5.1,2.9C1838.5,388.2,1845.6,388.9,1851,392.1z M1840.5,408.4l4.6-2.7
						c6-3.5,7.9-7.9,1.1-11.8c-6.4-3.7-14.8-2.9-21.1,0.7l-4.2,2.4c-6.7,3.9-6.2,8.9-0.8,12.1
						C1826.4,412.7,1834.6,411.8,1840.5,408.4"/>
							</g>
							<g>
								<path className="st3" d="M1812,369.6c9.3,5.3,8.2,12.1,0.2,16.7l-5.3,3.1c-8,4.6-19.6,5.3-28.9-0.1c-9.3-5.4-8.3-12.1-0.3-16.8
						l5.3-3.1C1791.1,364.8,1802.8,364.2,1812,369.6z M1802.3,386.7l5.2-3c6.2-3.6,7.1-8.1,0.6-11.8s-14.3-3.2-20.4,0.4l-5.2,3
						c-6.2,3.6-7.1,8.1-0.6,11.8S1796.2,390.3,1802.3,386.7"/>
							</g>
							<g>
								<path className="st3" d="M1779.4,335.6l-18.8,10.9c5.2-0.9,11-0.3,16.4,2.8c7.3,4.2,7.1,9.8-0.7,14.3l-20.5,11.9l-4.9-2.8l19.8-11.5
						c5.5-3.2,6.8-6.8,0.9-10.2c-5.4-3.1-12.7-3.6-19.4,0.3l-19.1,11.1l-4.9-2.8l46.3-26.9L1779.4,335.6z"/>
							</g>
							<g>
								<path className="st3" d="M1889,414c8.7,5.1,7.4,11.5-0.9,16.3l-4.9,2.9c-8.4,4.9-19.8,5.4-28.1,0.6c-5.1-2.9-6.7-6.5-5.3-9.5
						l-15.9,9.2l-4.9-2.8l43.5-25.3l4.6,2.7l-5.1,2.9C1876.5,410.1,1883.6,410.9,1889,414z M1878.6,430.3l4.6-2.7
						c6-3.5,7.9-7.9,1.1-11.8c-6.4-3.7-14.8-2.9-21.1,0.7l-4.2,2.4c-6.7,3.9-6.2,8.9-0.8,12.1
						C1864.4,434.6,1872.6,433.8,1878.6,430.3"/>
							</g>
							<g>
								<polygon className="st3" points="1878.5,446.5 1911,427.6 1915.9,430.4 1883.4,449.3 				"/>
							</g>
							<g>
								<path className="st3" d="M1919.1,425.7c-1.9-1.1-1.9-2.5-0.1-3.5c1.7-1,4.1-1,6,0.1c1.9,1.1,1.9,2.5,0.1,3.5
						C1923.4,426.8,1920.9,426.8,1919.1,425.7z"/>
							</g>
							<g>
								<path className="st3" d="M1987,471.5l4.7,2.7l-32.5,18.9c-6.7,3.9-17.9,3.3-26.2-1.5c-6.5-3.8-9.4-8.9-5.9-13l4.8,2.8
						c-1.3,2.8,0.4,5.5,4.9,8c5.7,3.3,12.7,3.7,17.7,0.8l7.4-4.3c-4,1.1-11.6,0.4-17.1-2.8c-8.5-4.9-7.3-11.2-0.1-15.4l5.4-3.1
						c7.4-4.3,18-4.9,26.7,0c6.1,3.5,6.5,7.6,5,10L1987,471.5z M1969.4,481.5l4.2-2.4c5.5-3.2,5.8-8.3,0.2-11.5
						c-6.4-3.7-13.2-3.6-19.4,0l-4.6,2.7c-6.1,3.5-6.4,7.5-0.2,11.1C1955.1,484.6,1963.3,485,1969.4,481.5"/>
							</g>
							<g>
								<path className="st3" d="M1942.8,445.1c7.3,4.2,7.1,9.8-0.7,14.3l-20.5,11.9l-4.9-2.8l19.8-11.5c5.5-3.2,6.8-6.8,0.9-10.2
						c-5.4-3.1-12.7-3.6-19.4,0.3l-19.1,11.1l-4.9-2.8l32.5-18.9l4.6,2.7l-5.5,3.2C1930.8,441.2,1937.1,441.8,1942.8,445.1z"/>
							</g>
							<g>
								<path className="st3" d="M1657.7,398.9l-0.1,6l-5.7,0l0.1-5.5c-6.2,0-12.7-1.7-19-5.4c-8.9-5.1-10.8-12.7-2.3-17.7
						c6.7-3.9,14.6-3.6,21.9-2l0-0.3c0.1-2.7,1-5.6,4.8-7.8c6.2-3.6,14.6-3.1,21.7,1c6.5,3.8,7.6,9,1.6,12.5l-2.5,1.4l-4-2.3l2.4-1.4
						c3.8-2.2,3.5-5.7-0.9-8.2c-4.5-2.6-9.8-3.1-13.8-0.8c-3,1.7-3.7,3.9-3.8,6.3l-0.5,20.7c2.5-0.6,5.1-1.6,7.6-3.1l4.5-2.6l4.1,2.4
						l-4.6,2.7C1665.8,396.8,1661.9,398.2,1657.7,398.9z M1637.5,392.5c4.3,2.5,9.4,3.8,14.6,3.6l0.5-19.1
						c-5.5-1.3-11.7-1.6-17.4,1.7C1628.6,382.6,1629.6,387.9,1637.5,392.5"/>
							</g>
							<g>
								<path className="st3" d="M1664.7,450.9l-9.3,5.4l8.4,4.8l-3.9,2.3l-8.4-4.8l-19.9,11.6c-3.3,1.9-3.5,3.4,0.2,5.5l3.3,1.9l-3.9,2.3
						l-4.1-2.4c-6.3-3.6-5.8-6.9-0.2-10.1l19.9-11.6l-5.6-3.2l3.9-2.3l5.6,3.2l9.3-5.4L1664.7,450.9z"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M1679.9,491.6l-23.4-13.5l-1.1,0.7c-5.6,3.3-7.3,7.6-0.4,11.6c4.9,2.8,10.5,3.1,15.3,1.5l4.3,2.5
							c-6.9,2.8-16.5,2-23.3-1.9c-8.5-4.9-9-11.5-0.3-16.6l5.2-3c8.8-5.1,19.7-5.6,28.9-0.3c9.3,5.4,7.7,11.6-0.3,16.2L1679.9,491.6z
							 M1661.2,475.4l-1,0.6l18.9,10.9l1.5-0.9c5.8-3.4,7.1-7.6,0.8-11.2C1674.3,470.7,1667.1,471.9,1661.2,475.4"/>
								</g>
								<g>
									<path className="st3" d="M1723.1,495.3l-4.5,2.6l-1-0.6c-6.2-3.6-14.2-3.5-22.4,1.3l-15.9,9.3l-4.9-2.8l32.5-18.9l4.5,2.6l-7.5,4.3
							c6.2-2.2,12.4-1.7,18.3,1.7L1723.1,495.3z"/>
								</g>
								<g>
									<path className="st3" d="M1746.4,498.1l-9.3,5.4l8.4,4.8l-3.9,2.3l-8.4-4.8l-19.9,11.6c-3.3,1.9-3.5,3.4,0.2,5.5l3.3,1.9l-3.9,2.3
							l-4.1-2.4c-6.3-3.6-5.8-6.9-0.2-10.1l19.9-11.6l-5.6-3.2l3.9-2.3l5.6,3.2l9.3-5.4L1746.4,498.1z"/>
								</g>
								<g>
									<path className="st3" d="M1768,520.5c8.1,4.7,7.7,10.5,1.3,14.3l-21.6,12.5l-4.7-2.7l4.1-2.4c-3.7,0.5-10.6-0.6-16.1-3.7
							c-7.7-4.5-8.1-9.7-2.2-13.1c6.3-3.6,15.3-2.9,23.1,1.6c5.2,3,6.5,6.2,6.4,8.4l6-3.5c4.4-2.6,4.9-6.3-0.3-9.3
							c-4.2-2.4-8.7-2.8-13.1-1.9l-4.5-2.6C1753.6,516,1761.5,516.8,1768,520.5z M1735.2,537.3c5.1,2.9,13,4.1,17.2,1.6
							c4.1-2.4,2.1-6.8-2.9-9.7c-5.4-3.1-11.6-3.8-16.1-1.1C1728.9,530.8,1730.1,534.4,1735.2,537.3"/>
								</g>
							</g>
							<g>
								<path className="st3" d="M1626.5,438.8c7.3,4.2,7.1,9.8-0.7,14.3l-20.5,11.9l-4.9-2.8l19.8-11.5c5.5-3.2,6.8-6.8,0.9-10.2
						c-5.4-3.1-12.7-3.6-19.4,0.3l-19.1,11.1l-4.9-2.8l32.5-18.9l4.6,2.7l-5.5,3.2C1614.6,434.9,1620.9,435.6,1626.5,438.8z"/>
							</g>
							<g>
								<path className="st3" d="M1583.2,435.8l-23.4-13.5l-1.1,0.7c-5.6,3.3-7.3,7.6-0.4,11.6c4.9,2.8,10.5,3.1,15.3,1.5l4.3,2.5
						c-6.9,2.8-16.5,2-23.3-1.9c-8.5-4.9-9-11.5-0.3-16.6l5.2-3c8.8-5.1,19.7-5.6,28.9-0.3c9.3,5.4,7.7,11.6-0.3,16.2L1583.2,435.8z
						 M1564.5,419.5l-1,0.6l18.9,10.9l1.5-0.9c5.8-3.4,7.1-7.6,0.8-11.2C1577.6,414.9,1570.4,416.1,1564.5,419.5"/>
							</g>
							<g>
								<path className="st3" d="M1822.2,551.8c7.3,4.2,7.1,9.8-0.7,14.3l-20.5,11.9l-4.9-2.8l19.8-11.5c5.5-3.2,6.8-6.8,0.9-10.2
						c-5.4-3.1-12.7-3.6-19.4,0.3l-19.1,11.1l-4.9-2.8l32.5-18.9l4.6,2.7l-5.5,3.2C1810.2,547.9,1816.5,548.5,1822.2,551.8z"/>
							</g>
							<g>
								<polygon className="st3" points="1758,553.2 1790.4,534.4 1795.3,537.2 1762.8,556 				"/>
							</g>
							<g>
								<path className="st3" d="M1798.5,532.5c-1.9-1.1-1.9-2.5-0.1-3.5s4.1-1,6,0.1s1.9,2.5,0.1,3.5S1800.4,533.5,1798.5,532.5z"/>
							</g>
							<g>
								<path className="st3" d="M1992.7,640.3l-9.3,5.4l8.4,4.8l-3.9,2.3l-8.4-4.8l-19.9,11.6c-3.3,1.9-3.5,3.4,0.2,5.5l3.3,1.9l-3.9,2.3
						l-4.1-2.4c-6.3-3.6-5.8-6.9-0.2-10.1l19.9-11.6l-5.6-3.2l3.9-2.3l5.6,3.2l9.3-5.4L1992.7,640.3z"/>
							</g>
							<g>
								<path className="st3" d="M1954.6,628.2c7.3,4.2,7.1,9.8-0.7,14.3l-20.5,11.9l-4.9-2.8l19.8-11.5c5.5-3.2,6.8-6.8,0.9-10.2
						c-5.4-3.1-12.7-3.6-19.4,0.3l-19.1,11.1l-4.9-2.8l32.5-18.9l4.6,2.7l-5.5,3.2C1942.6,624.3,1948.9,625,1954.6,628.2z"/>
							</g>
							<g>
								<path className="st3" d="M1911.2,625.2l-23.4-13.5l-1.1,0.7c-5.6,3.3-7.3,7.6-0.4,11.6c4.9,2.8,10.5,3.1,15.3,1.5l4.3,2.5
						c-6.9,2.8-16.5,2-23.3-1.9c-8.5-4.9-9-11.5-0.3-16.5l5.2-3c8.8-5.1,19.7-5.6,28.9-0.3c9.3,5.4,7.7,11.6-0.3,16.2L1911.2,625.2z
						 M1892.5,608.9l-1,0.6l18.9,10.9l1.5-0.9c5.8-3.4,7.1-7.6,0.8-11.2C1905.6,604.2,1898.5,605.5,1892.5,608.9"/>
							</g>
							<g>
								<path className="st3" d="M1881.9,586.3c7.2,4.1,6.7,9.7-1,14.2l-20.5,11.9l-4.9-2.8l19.8-11.5c5.5-3.2,6.5-7,1.2-10.1
						c-5.4-3.1-12.6-3.5-19.3,0.4l-19.1,11.1l-4.9-2.8l19.8-11.5c5.5-3.2,6.5-7,1.2-10.1c-5.4-3.1-12.6-3.5-19.3,0.4l-19.1,11.1
						l-4.9-2.8l32.5-18.9l4.6,2.7l-5.5,3.2c5.2-1.3,11.4-0.7,17.1,2.6c4.9,2.8,6.1,6.3,4.1,9.7C1869.7,582.2,1876.2,583,1881.9,586.3
						z"/>
							</g>
						</g>
					</g>
				</g>

				{/* Office */}
				<g id="office-group"
				   className="office-group building-group"
				   data-type="office"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-office">
						<path className="st0"
							  d="M902.5,255.5c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3s3.2-6.4,7-4C899.3,245.5,902.5,251,902.5,255.5z"/>
						<path className="st0"
							  d="M900.2,281c0,3-2.1,4.3-4.7,2.8s-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8C898.1,274.2,900.2,278,900.2,281z"/>
						<path className="st0" d="M898.2,303.5c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5
				C897,299.7,898.2,301.8,898.2,303.5z"/>
						<path className="st1" d="M927.6,208.7c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
				C912.3,161.6,927.6,187.5,927.6,208.7z"/>
					</g>
					<g id="icon-office">
						<g>
							<path className="sticon" d="M889.7,206.3c0.5,0.3,0.9,0.5,1.4,0.8c0-0.5,0-1,0-1.5c-0.3-12.7-0.6-25.4-0.9-38.1c0-1.6,0-1.6,1.2-1
					c5.2,3,10.4,5.9,15.6,8.9c1,0.6,1.1,0.8,1.2,2.2c0.3,13.4,0.6,26.7,0.9,40.1c0,1.4-0.1,1.5-1.1,0.9c-10-5.7-20-11.4-30-17
					c-0.9-0.5-1.1-0.8-1.1-2.1c-0.3-11-0.5-22-0.8-33c0-0.8,0.2-1.1,0.7-1.2c3.5-0.2,7.1-0.5,10.6-0.7c1-0.1,1.4,0.4,1.4,2
					c0.3,12.6,0.6,25.2,0.9,37.8C889.7,205,889.7,205.6,889.7,206.3z M891.6,168.5c0.3,13.2,0.6,26.3,0.9,39.4
					c1.2,0.7,2.4,1.4,3.6,2.1c0-2-0.1-3.9-0.1-5.8c0-1.9,0.6-3,1.8-3.3c2.6-0.6,6,3.7,6.1,7.9c0.1,1.9,0.1,3.8,0.1,5.7
					c1.3,0.7,2.4,1.4,3.6,2.1c-0.3-13.1-0.6-26.2-0.9-39.4C901.7,174.3,896.7,171.4,891.6,168.5z M888.4,205.5
					c-0.3-13-0.6-25.9-0.9-38.9c-0.3,0-0.6,0-0.9,0c-2.8,0.2-5.6,0.4-8.4,0.6c-0.5,0-0.6,0.3-0.6,1c0.3,10.2,0.5,20.4,0.7,30.6
					c0,0.3,0,0.6,0.1,1C881.7,201.7,885,203.6,888.4,205.5z M902.7,213.7c-0.1-2.2,0-4.2-0.2-6.4c-0.1-1.6-1.3-3.5-2.5-4.2
					c-1.2-0.7-2.5-0.2-2.5,1.3c-0.1,2,0.1,4.2,0.1,6.4C899.3,211.8,901,212.7,902.7,213.7z"/>
							<path className="sticon" d="M900.1,178.7c0-0.7,0-1.5-0.1-2.2c0-0.7,0.2-1,0.7-0.7c1.2,0.7,2.5,1.4,3.7,2.1c0.4,0.3,0.7,0.8,0.7,1.4
					c0,1.6,0.1,3.2,0.1,4.8c0,0.7-0.3,0.9-0.8,0.6c-1.2-0.7-2.4-1.4-3.6-2.1c-0.5-0.3-0.7-0.8-0.7-1.4
					C900.1,180.4,900.1,179.6,900.1,178.7z M901.5,178.1c0,1.1,0,2.1,0.1,3.1c0.8,0.4,1.5,0.9,2.3,1.3c0-1.1,0-2.1-0.1-3.1
					C903,178.9,902.3,178.5,901.5,178.1z"/>
							<path className="sticon" d="M903,194c0.6,0.3,1.1,0.7,1.7,1c0.5,0.3,0.8,0.8,0.8,1.5c0,1.6,0.1,3.2,0.1,4.7c0,0.8-0.3,0.9-0.8,0.6
					c-1.1-0.6-2.2-1.3-3.4-1.9c-0.6-0.3-0.9-0.9-0.9-1.7c0-1.5-0.1-3.1-0.1-4.6c0-0.7,0.2-0.9,0.8-0.6
					C901.8,193.4,902.4,193.7,903,194z M904.1,196.6c-0.8-0.4-1.5-0.9-2.3-1.3c0,1.1,0,2.1,0.1,3.1c0.8,0.4,1.5,0.8,2.3,1.3
					C904.2,198.6,904.2,197.6,904.1,196.6z"/>
							<path className="sticon" d="M896.4,190.3c0.6,0.3,1.2,0.7,1.8,1c0.5,0.3,0.7,0.7,0.7,1.4c0,1.6,0.1,3.2,0.1,4.8c0,0.7-0.2,0.9-0.8,0.6
					c-1.2-0.7-2.3-1.3-3.5-2c-0.5-0.3-0.8-0.9-0.9-1.6c-0.1-1.6-0.1-3.2-0.1-4.7c0-0.7,0.2-0.9,0.8-0.6
					C895.2,189.6,895.8,189.9,896.4,190.3z M895.3,191.5c0,1.1,0,2.1,0.1,3.1c0.8,0.4,1.5,0.9,2.3,1.3c0-1.1,0-2.1-0.1-3.1
					C896.8,192.4,896,191.9,895.3,191.5z"/>
							<path className="sticon" d="M898.6,178c0,0.8,0,1.5,0.1,2.3c0,0.7-0.2,0.9-0.7,0.6c-1.2-0.7-2.4-1.4-3.6-2.1c-0.5-0.3-0.7-0.9-0.8-1.5
					c-0.1-1.6-0.1-3.1-0.1-4.6c0-0.7,0.2-1,0.8-0.7c1.2,0.7,2.3,1.3,3.5,2c0.5,0.3,0.8,0.8,0.8,1.5
					C898.5,176.4,898.6,177.2,898.6,178z M897.2,175.6c-0.8-0.5-1.5-0.9-2.3-1.3c0,1.1,0,2.1,0.1,3.1c0.8,0.4,1.5,0.9,2.3,1.3
					C897.2,177.7,897.2,176.7,897.2,175.6z"/>
							<path className="sticon" d="M902.8,185.5c0.6,0.3,1.2,0.7,1.8,1c0.5,0.3,0.7,0.8,0.7,1.4c0,1.6,0.1,3.2,0.1,4.8c0,0.7-0.3,0.9-0.8,0.6
					c-1.2-0.6-2.3-1.3-3.5-2c-0.5-0.3-0.8-0.8-0.8-1.6c0-1.6-0.1-3.2-0.1-4.7c0-0.7,0.2-0.9,0.7-0.6
					C901.6,184.7,902.2,185.1,902.8,185.5C902.8,185.5,902.8,185.5,902.8,185.5z M901.7,189.8c0.8,0.5,1.5,0.9,2.3,1.3
					c0-1.1,0-2.1-0.1-3.1c-0.8-0.4-1.5-0.9-2.3-1.3C901.7,187.7,901.7,188.7,901.7,189.8z"/>
							<path className="sticon" d="M896.2,181.7c0.6,0.3,1.2,0.7,1.8,1c0.5,0.3,0.8,0.8,0.8,1.4c0,1.6,0.1,3.2,0.1,4.8c0,0.7-0.3,0.9-0.7,0.6
					c-1.2-0.6-2.3-1.3-3.5-2c-0.6-0.3-0.9-0.9-0.9-1.6c0-1.6-0.1-3.1-0.1-4.6c0-0.7,0.3-1,0.8-0.6
					C895,181.1,895.6,181.4,896.2,181.7z M895.1,182.9c0,1.1,0,2.1,0.1,3.1c0.8,0.4,1.5,0.9,2.3,1.3c0-1.1,0-2.1-0.1-3.1
					C896.6,183.7,895.8,183.3,895.1,182.9z"/>
							<path className="sticon" d="M883.3,200.4c-0.6-0.4-1.3-0.7-1.9-1.1c-0.7-0.4-1-0.9-1-1.8c0-1.8-0.1-3.5-0.1-5.3c0-0.9,0.3-1.1,0.9-0.7
					c1.3,0.8,2.7,1.5,4,2.3c0.6,0.3,0.9,0.9,0.9,1.6c0.1,1.9,0.1,3.7,0.1,5.6c0,0.7-0.3,0.9-0.8,0.6
					C884.7,201.2,884,200.8,883.3,200.4z M884.7,195.5c-1.1-0.6-2-1.1-3-1.7c0,1.4,0.1,2.6,0.1,4c0.9,0.5,1.7,1,2.6,1.4
					c0.1,0.1,0.3-0.1,0.3-0.3C884.7,197.8,884.7,196.7,884.7,195.5z"/>
							<path className="sticon" d="M882.9,182.8c0.7,0.4,1.4,0.8,2.1,1.2c0.6,0.3,0.8,0.9,0.9,1.5c0.1,1.9,0.1,3.8,0.1,5.7
					c0,0.6-0.2,0.8-0.7,0.6c-1.4-0.8-2.8-1.6-4.3-2.4c-0.5-0.3-0.8-0.8-0.8-1.5c0-1.9-0.1-3.7-0.1-5.6c0-0.7,0.2-1,0.8-0.6
					C881.5,182,882.2,182.4,882.9,182.8z M881.5,183.9c0,1.4,0.1,2.6,0.1,4c0.9,0.5,1.8,1,2.6,1.5c0.1,0.1,0.3-0.1,0.3-0.2
					c0-1.1,0-2.3-0.1-3.5C883.4,185,882.5,184.4,881.5,183.9z"/>
							<path className="sticon" d="M882.8,180.6c-0.7-0.4-1.4-0.8-2.1-1.2c-0.5-0.3-0.8-0.8-0.8-1.5c0-1.9-0.1-3.7-0.1-5.6
					c0-0.7,0.3-0.9,0.8-0.6c1.4,0.8,2.8,1.6,4.1,2.3c0.6,0.3,0.8,0.9,0.8,1.6c0,1.8,0.1,3.6,0.1,5.4c0,0.8-0.2,1-0.9,0.7
					C884.2,181.3,883.5,181,882.8,180.6z M881.3,173.9c0,1.4,0.1,2.6,0.1,3.9c1,0.6,2,1.1,2.9,1.7c0-1.3-0.1-2.6-0.1-3.9
					C883.2,175.1,882.3,174.5,881.3,173.9z"/>
						</g>
					</g>
					<polygon
						id="office-polygon"
						className="office-polygon building-polygon st2"
						points="580,452.9 590.9,446.6 651.5,481.5 651.5,484 651.8,483.8 654,484.9 654.2,485.1
			654.5,485.4 655,486 655.6,486.7 662.1,490.4 662.5,490.7 662.9,491.4 663.6,493.5 664.2,495.8 664.6,497.9 664.9,499.6 665.2,502
			879.1,625.4 883.6,622.6 883.9,622.4 884.1,622.4 884.3,622.3 884.5,622.4 884.7,622.4 884.8,622.4 885,622.4 885.1,622.5
			885.4,622.7 886,622.9 887.9,624 888.2,624.2 888.3,624.3 888.4,624.4 888.5,624.5 888.5,624.6 888.4,624.7 888.4,624.8
			888.3,624.9 888.3,625.1 888.3,625.2 888.2,625.3 888.2,625.4 888.1,625.4 888,625.4 887.8,625.5 887.7,625.9 887.6,626.3
			887.4,626.7 887.2,627.1 886.9,627.4 885.8,628.1 884.9,628.7 940.1,660.6 1199.8,511.3 1030.9,413.3 1030.9,395 1019.2,388.3
			1019.2,316.9 970.7,288.8 970.7,272 801.5,174.6 718.7,222.4 718.7,294.1 743,308.1 655.3,358.8 655,363.1 540,429.8"
					/>
					<g className="building-caption">
						<g>
							<g>
								<path className="st3" d="M798.8,365.7c13.4,7.7,11.9,17.5,0.3,24.2l-7.7,4.5c-11.5,6.7-28.4,7.6-41.7-0.1
						c-13.5-7.8-12-17.5-0.4-24.2l7.7-4.5C768.6,358.9,785.5,358,798.8,365.7z M784.8,390.4l7.5-4.4c8.9-5.2,10.2-11.7,0.9-17.1
						c-9.3-5.4-20.6-4.6-29.5,0.6l-7.5,4.4c-8.9,5.2-10.2,11.7-0.9,17.1C764.6,396.4,775.9,395.6,784.8,390.4"/>
							</g>
							<g>
								<path className="st3" d="M875,387.8l-5.7,3.3l-6.1-3.5c-5-2.9-9.5-2.8-14.7,0.2l-6.8,3.9l11.6,6.7l-5.7,3.3l-11.6-6.7l-41.2,24l-7-4
						l41.2-24l-6.9-4l5.7-3.3l6.9,4l6.2-3.6c9.1-5.3,17.8-5.6,27.4-0.1L875,387.8z"/>
							</g>
							<g>
								<path className="st3" d="M908.4,420.8c-2.7-1.5-2.7-3.6-0.2-5c2.5-1.4,6-1.4,8.6,0.1c2.7,1.5,2.7,3.6,0.2,5
						C914.6,422.4,911.1,422.4,908.4,420.8z"/>
							</g>
							<g>
								<path className="st3" d="M982.8,503.7L949,484.1l-1.6,1c-8.2,4.7-10.5,11-0.6,16.7c7.1,4.1,15.2,4.4,22.1,2.2l6.3,3.6
						c-10,4-23.8,2.9-33.7-2.8c-12.3-7.1-13.1-16.6-0.4-23.9l7.5-4.4c12.7-7.4,28.5-8.1,41.7-0.4c13.5,7.8,11.1,16.7-0.4,23.4
						L982.8,503.7z M955.7,480.2l-1.5,0.9l27.3,15.8l2.2-1.3c8.3-4.8,10.3-10.9,1.1-16.2C974.7,473.4,964.4,475.2,955.7,480.2"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M907.4,406.6l-5.7,3.3l-6.1-3.5c-5-2.9-9.5-2.8-14.7,0.2l-6.8,3.9l11.6,6.7l-5.7,3.3l-11.6-6.7l-41.2,24
							l-7-4l41.2-24l-6.9-4l5.7-3.3l6.9,4l6.2-3.6c9.1-5.3,17.8-5.6,27.4-0.1L907.4,406.6z"/>
								</g>
								<g>
									<polygon className="st3" points="849.9,450.8 896.8,423.5 903.8,427.6 856.9,454.9 					"/>
								</g>
								<g>
									<path className="st3" d="M938.9,446.6c11.9,6.9,11.9,15.8,2.6,21.9l-6.3-3.6c5.4-4.2,7.2-9.9-1.7-15c-9.7-5.6-20.4-4.6-29.2,0.5
							l-8.1,4.7c-8.8,5.1-10.5,11.3-0.8,16.9c8.9,5.2,18.7,4.1,25.9,1l6.3,3.6c-10.5,5.4-25.9,5.4-37.8-1.4
							c-13.1-7.6-12.1-17.2-0.3-24.1l7.9-4.6C909.2,439.6,925.8,439,938.9,446.6z"/>
								</g>
							</g>
						</g>
					</g>
				</g>

				{/* Industry */}
				<g id="industry-group"
				   className="industry-group building-group"
				   data-type="industry"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-industry">
						<path className="st0"
							  d="M251.9,592.7c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3s3.2-6.4,7-4C248.7,582.7,251.9,588.2,251.9,592.7z"/>
						<path className="st0"
							  d="M249.6,618.2c0,3-2.1,4.3-4.7,2.8s-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8S249.6,615.2,249.6,618.2z"/>
						<path className="st0" d="M247.6,640.7c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5
				C246.4,636.9,247.6,639,247.6,640.7z"/>
						<path className="st1" d="M277,545.9c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
				C261.7,498.8,277,524.7,277,545.9z"/>
					</g>
					<g id="icon-industry">
						<g>
							<path className="sticon" d="M262.3,557.6c-0.2,0.5-0.5,0.5-1,0.3c-11-5.9-22.1-11.8-33.1-17.6c-0.8-0.4-1.1-0.9-1.1-2
					c-0.5-10.5-1.1-21.1-1.6-31.6c-0.1-1.1,0.2-1.2,0.9-0.8c1.1,0.6,2.1,1.1,3.2,1.7c1,0.5,1.2,0.8,1.2,2.2
					c0.2,3.9,0.4,7.8,0.6,11.7c0,0.3,0,0.5,0,0.8c1,0.6,2,1.1,3.1,1.7c0-0.2,0-0.5,0-0.7c-0.2-4-0.4-8.1-0.6-12.1
					c0-1,0.2-1.2,0.9-0.8c1.2,0.6,2.3,1.2,3.5,1.8c0.6,0.3,0.9,0.8,0.9,1.6c0.2,4,0.4,8.1,0.6,12.1c0,0.2,0,0.5,0,0.8
					c1,0.5,2,1.1,2.9,1.5c0.1,0,0.2-0.3,0.2-0.5c-0.1-2.1-0.2-4.1-0.3-6.2c0-0.5,0-0.9,0.3-1c0.4-0.1,0.7,0.3,1,0.8
					c2.2,3.2,4.5,6.5,6.7,9.7c0.2,0.2,0.3,0.5,0.6,0.8c-0.1-1.3-0.1-2.4-0.2-3.5c0-0.7-0.1-1.5-0.1-2.2c0-0.5,0-0.9,0.4-1
					c0.4-0.1,0.7,0.4,1,0.8c1.8,2.6,3.7,5.2,5.5,7.9c0.9,1.4,1.9,2.7,2.8,4.1c0.2,0.3,0.4,0.6,0.6,1
					C261.7,545.1,262,551.3,262.3,557.6z M228.6,538.4c10.7,5.7,21.4,11.4,32.1,17.1c0-0.1,0-0.2,0-0.2c-0.3-5.4-0.5-10.7-0.8-16.1
					c0-0.2-0.2-0.7-0.4-0.9c-1.4-2-2.8-4.1-4.3-6.1c-0.9-1.2-1.7-2.5-2.7-3.8c0,0.4,0,0.6,0,0.8c0.1,1.7,0.2,3.4,0.3,5.1
					c0,0.5,0,1-0.3,1c-0.3,0-0.7-0.4-0.9-0.8c-2.3-3.2-4.5-6.4-6.8-9.7c-0.2-0.2-0.4-0.5-0.6-0.8c0,0.4,0,0.6,0,0.8
					c0.1,1.7,0.2,3.5,0.3,5.2c0,0.8-0.2,1-0.8,0.7c-0.2-0.1-0.3-0.2-0.5-0.2c-5-2.6-9.9-5.3-14.9-7.9c-0.2-0.1-0.4-0.2-0.6-0.3
					C228.1,527.7,228.3,533,228.6,538.4z M229.4,509.2c-0.8-0.4-1.5-0.8-2.3-1.2c0.2,4.1,0.4,8.1,0.6,12.2c0.8,0.4,1.5,0.8,2.3,1.2
					C229.8,517.3,229.6,513.3,229.4,509.2z M238.4,525.9c-0.2-4.1-0.4-8.1-0.6-12.2c-0.8-0.4-1.5-0.8-2.3-1.2
					c0.2,4.1,0.4,8.1,0.6,12.2C236.9,525.1,237.6,525.5,238.4,525.9z"/>
							<path className="sticon" d="M231.6,497.6c0.7,0.4,1.3,0.7,2,1.1c0.5,0.3,0.8,0.8,0.9,1.4c0.4,3.6-0.9,5.5-3.2,5c-0.7-0.2-1.5-0.6-2.2-1
					c-0.8-0.4-1.6-0.9-2.4-1.3c-0.7-0.4-1-0.9-1-1.8c-0.2-2.9,0.5-4.6,2.4-4.8c0.8-0.1,1.8,0.4,2.7,0.9
					C231,497.3,231.3,497.5,231.6,497.6z M233,500.3c-1.1-0.6-2.1-1.1-3.2-1.7c-1-0.5-1.8-0.3-2.3,0.4c-0.2,0.3-0.3,0.7-0.4,1.1
					c0,0.1,0.1,0.1,0.1,0.2c0.7-0.2,1.5-0.4,2.2-0.6c0.5-0.1,0.9,0.1,1.2,0.8c0.3,0.7,0.2,1.1-0.4,1.3c-0.4,0.1-0.7,0.2-1.1,0.3
					C231.5,503.8,233.3,503,233,500.3z"/>
							<path className="sticon" d="M243,503.7c0.7,0.4,1.3,0.7,2,1.1c0.6,0.3,0.9,0.8,0.9,1.5c0.4,3.6-1,5.5-3.2,5c-0.7-0.1-1.4-0.5-2.1-0.9
					c-0.9-0.4-1.7-0.9-2.6-1.4c-0.7-0.4-1-0.9-1-1.8c-0.2-2.9,0.5-4.6,2.4-4.8c0.9-0.1,1.9,0.4,2.8,1
					C242.6,503.5,242.8,503.6,243,503.7z M240.6,508.3c2.4,1.7,4.2,0.8,3.8-1.8c-1.1-0.6-2.1-1.1-3.2-1.7c-0.8-0.4-1.4-0.4-1.9,0
					c-0.4,0.3-0.7,0.8-0.7,1.7c0.8-0.2,1.6-0.4,2.3-0.6c0.5-0.1,0.9,0.2,1.2,0.9c0.2,0.6,0.1,1-0.3,1.2
					C241.4,508,241.1,508.1,240.6,508.3z"/>
							<path className="sticon" d="M247.3,540.6c0,0.9,0.1,1.8,0.1,2.8c0,0.9-0.2,1.1-0.9,0.8c-1.4-0.7-2.8-1.5-4.3-2.3
					c-0.6-0.3-0.9-0.9-1-1.7c-0.1-1.9-0.2-3.7-0.3-5.6c0-0.9,0.2-1.1,0.9-0.7c1.4,0.7,2.8,1.5,4.2,2.2c0.7,0.4,1,0.9,1,1.8
					C247.2,538.7,247.3,539.6,247.3,540.6z M245.9,541.7c-0.1-1.4-0.1-2.7-0.2-4c-1-0.5-2-1.1-3-1.6c0.1,1.4,0.1,2.7,0.2,4
					C243.9,540.7,244.8,541.2,245.9,541.7z"/>
							<path className="sticon" d="M237.3,535.3c0,1,0.1,1.9,0.1,2.9c0,0.7-0.2,0.9-0.8,0.7c-1.5-0.8-2.9-1.6-4.4-2.4
					c-0.5-0.3-0.9-0.8-0.9-1.5c-0.1-2-0.2-4-0.3-5.9c0-0.7,0.3-0.9,0.8-0.6c1.5,0.8,2.9,1.5,4.4,2.3c0.6,0.3,0.9,0.8,0.9,1.6
					C237.3,533.3,237.3,534.3,237.3,535.3z M232.7,530.8c0.1,1.4,0.1,2.7,0.2,4c1,0.5,2,1.1,3,1.6c-0.1-1.3-0.1-2.7-0.2-4
					C234.7,531.9,233.7,531.4,232.7,530.8z"/>
							<path className="sticon" d="M251.2,542.6c0-1-0.1-1.9-0.1-2.9c0-0.7,0.2-1,0.7-0.7c1.5,0.8,2.9,1.6,4.4,2.4c0.5,0.3,0.9,0.8,0.9,1.5
					c0.1,2,0.2,4,0.3,5.9c0,0.7-0.3,0.9-0.8,0.6c-1.4-0.7-2.8-1.5-4.3-2.3c-0.6-0.3-0.9-0.9-1-1.7
					C251.3,544.4,251.2,543.5,251.2,542.6z M252.8,545.4c1,0.6,2,1.1,3,1.6c-0.1-1.4-0.1-2.7-0.2-4c-1-0.5-2-1.1-3-1.6
					C252.6,542.7,252.7,544,252.8,545.4z"/>
						</g>
					</g>
					<path
						id="industry-polygon"
						className="industry-polygon building-polygon st2"
						d="M20.4,730.8l399.5,231l460-265.9L480.1,464.5l-3.7,2.1l-5.8-3.3L159.2,643.5l0,6.7L20.4,730.8z"/>
					<g className="building-caption _flip">
						<g>
							<g>
								<polygon className="st3" points="367.1,801.4 327.4,778.3 333.3,774.9 373,797.9 				"/>
							</g>
							<g>
								<path className="st3" d="M323.5,772.6c-2.3,1.3-5.2,1.3-7.3,0.1c-2.1-1.2-2.1-2.9,0.2-4.2c2.3-1.3,5.2-1.3,7.3-0.1
						C325.7,769.5,325.7,771.3,323.5,772.6z"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M573.7,635.8l5.5,3.2l-1.2,0.7c-7.6,4.4-7.4,10.1,2.6,15.9l19.5,11.3l-5.9,3.4l-39.7-23.1l5.5-3.2l9.2,5.3
							c-4.6-4.4-3.6-8.8,3.6-13L573.7,635.8z"/>
								</g>
								<g>
									<path className="st3" d="M523.1,652.1l11.4,6.6l10.2-5.9l4.8,2.8l-10.2,5.9l24.3,14.1c4,2.3,7.2,2.5,11.7-0.1l4-2.3l4.8,2.8l-5,2.9
							c-7.6,4.4-14.6,4.1-21.4,0.1l-24.3-14.1l-6.8,3.9l-4.8-2.8l6.8-3.9l-11.4-6.6L523.1,652.1z"/>
								</g>
								<g>
									<path className="st3" d="M497.6,679c8.3-4.8,19.9-5.6,27.2-1.4l0.4,0.2l-5.4,3.1l-0.2-0.1c-4.9-2.8-11.7-2.7-17.7,0.8
							c-5.5,3.2-7.3,6.9-2.8,9.5c2.7,1.6,5.7,1.8,9.3,0.5l15.1-5.4c7.6-2.7,14.3-3.1,20.1,0.3c7.1,4.1,5.3,11-4.1,16.4
							c-9.7,5.6-21.4,6.8-29,2.3l-0.4-0.2l5.4-3.1l0.2,0.1c5,2.9,12.9,2.1,19.5-1.7c6.2-3.6,8.2-7.5,3.1-10.5
							c-2.8-1.6-6.6-1.8-10.5-0.3l-14.6,5.3c-7.3,2.7-14.2,2.6-19.4-0.5C486.3,689.9,488.8,684,497.6,679z"/>
								</g>
							</g>
							<g>
								<path className="st3" d="M471.6,695l39.7,23.1l-5.6,3.2l-6.7-3.9c2.6,3.7,1.3,8.2-5.7,12.2c-8.7,5-20.3,4.7-29.9-0.8l-25-14.6
						l5.9-3.4l24.2,14.1c6.7,3.9,14.4,5,21.3,1c6.6-3.8,7.4-9.1-0.8-13.8l-23.3-13.6L471.6,695z"/>
							</g>
							<g>
								<path className="st3" d="M403.3,714.9l5.9-3.4l56.6,32.9l-5.6,3.2L454,744c1.9,3.1,0.3,8.1-6.4,11.9c-10.5,6.1-24.5,5.1-34.5-0.7
						l-6-3.5c-10.3-6-11.3-14.1-1.2-19.9c6.2-3.6,13.7-4.7,20.2-3.7L403.3,714.9z M442.4,737.6l-5.1-3c-8.1-4.7-18.8-4.4-25.4-0.6
						c-7.6,4.4-5.8,10.2,1.5,14.4l5.6,3.2c7.3,4.2,16.7,5.6,25,0.9C451.7,748,450,742.1,442.4,737.6"/>
							</g>
							<g>
								<path className="st3" d="M364.4,755.9c9-5.2,20.7-5,30.2,0.6l25,14.6l-5.9,3.4l-24.2-14.1c-6.7-3.9-14.4-4.8-21.5-0.7
						c-6.6,3.8-7.6,9,0.6,13.7L392,787l-5.9,3.4l-39.7-23.1l5.6-3.2l6.7,3.9C356.1,764.3,357.5,759.9,364.4,755.9z"/>
							</g>
							<g>
								<polygon className="st3" points="613.2,613.3 638.2,644.8 646.4,655.7 640.7,659 632.3,648.3 577.7,633.8 583.5,630.4 629.4,643
						607.4,616.6 				"/>
							</g>
						</g>
					</g>
				</g>

				{/* Health & Wellness */}
				<g id="health-group"
				   className="health-group building-group"
				   data-type="health_and_wellness"
				   onMouseEnter={onMouseEnter}
				   onMouseLeave={onMouseLeave}
				   onClick={onClick}
				>
					<g id="pin-health">
						<path className="st0"
							  d="M1386.7,65.6c0,4.4-3.2,6.4-7,4c-3.8-2.3-7-7.6-7-12.3s3.2-6.4,7-4C1383.5,55.6,1386.7,61.1,1386.7,65.6z"/>
						<path className="st0"
							  d="M1384.4,91.1c0,3-2.1,4.3-4.7,2.8s-4.7-5.1-4.7-8.3s2.1-4.3,4.7-2.8C1382.3,84.3,1384.4,88.1,1384.4,91.1z"/>
						<path className="st0" d="M1382.4,113.6c0,1.7-1.3,2.5-2.8,1.5c-1.5-0.8-2.8-3-2.8-4.7s1.3-2.5,2.8-1.5
							C1381.2,109.8,1382.4,111.9,1382.4,113.6z"/>
						<path className="st5" d="M1411.8,18.8c0,21-15.3,29.5-34.2,18.5c-18.9-10.8-34.2-36.7-34.2-57.9c0-21,15.3-29.5,34.2-18.5
							C1396.5-28.3,1411.8-2.4,1411.8,18.8z"/>
					</g>
					<g id="icon-health">
						<path className="sticon" d="M1354.4-13.1c-0.1-1-0.3-3.8-0.3-4.1c-0.1-2.6,0.3-4.7,1.2-6.4c3.2-6.6,11.2-4.6,17.7,4.5
				c0.7,0.9,1.3,2,1.8,2.8c0.5-0.3,0.9-0.6,1.2-0.9c1.9-1.7,4.4-2.1,7.5-0.7c5.2,2.3,10.4,8.8,12.9,16.3c0.6,1.9,1.1,3.8,1.6,5.6
				c0.1,1.1,0.2,2.2,0.4,3.3c0,1-0.1,2.1-0.1,3.1c-0.4,5.3-2.4,8.9-5,11.7c-3.3,3.5-7.6,5.4-12.1,6.7c-0.9,0.3-1.6-0.1-2.6-1.2
				c-4.4-4.8-8.7-9.9-12.6-15.6c-3.7-5.3-7-10.9-9.2-17C1355.8-7.8,1355.2-10.5,1354.4-13.1z M1357.3-14.5c0.6,5.5,2.3,10,4.6,14.6
				c4.7,9.1,10.7,16.6,17,23.6c0.3,0.3,0.7,0.4,0.9,0.4c3.3-1,6.5-2.4,9.1-4.6c2.5-2.1,4.6-4.8,5.6-8.9c1-4.4,0.7-9.4-2.3-15.3
				c-2.7-5.5-6.9-9.4-10.4-9.7c-2.4-0.2-3.8,1.2-4.9,3.2c-0.8,1.5-1.9,1-3.2-1.4c-0.4-0.8-0.8-1.6-1.2-2.3c-2.7-4.5-5.8-7.7-9.2-8.1
				C1359.1-23.6,1356.8-19.7,1357.3-14.5z"/>
						<path className="sticon" d="M1371.7-1.5c-0.5,1.9-0.7,2-2.4,1.2c-2-0.9-4.1-1.9-6.1-2.8c-0.7-0.3-1.4-1-1.7-2.1c-0.3-1-0.3-1.8,0.3-2.1
				c0.3-0.1,0.7,0,1.1,0.1c1.7,0.7,3.3,1.5,5,2.3c0.6,0.3,0.8,0.1,0.8-0.5c0.2-1.6,0.4-3,0.6-4.6c0.1-1.1,0.4-1.7,1.4-1.3
				c0.8,0.3,1.4,1.3,1.9,3c1.3,4.6,2.6,9.1,4,13.9c0.1-0.3,0.2-0.4,0.2-0.6c0.4-3.7,0.8-7.3,1.2-11c0.1-1,0.4-1.6,1.4-1.3
				c0.8,0.3,1.5,1.3,1.9,2.8c1,3.6,2.1,7.2,3.1,10.9c1.9,0.9,3.7,1.7,5.5,2.6c0.5,0.2,1.2,0.7,1.5,1.2c0.4,0.7,0.7,1.8,0.6,2.3
				c-0.1,0.5-0.7,0.9-1.2,0.7c-2.4-1-4.8-2.2-7.2-3.3c-0.9-0.4-1.4-1.4-1.7-2.5c-0.4-1.4-0.8-2.9-1.4-4.4c-0.3,2.5-0.6,5-0.9,7.4
				c-0.2,1.4-0.3,2.9-0.5,4.4c-0.1,0.9-0.5,1.4-1.4,0.9c-0.9-0.4-1.4-1.3-1.7-2.4C1374.6,8.5,1373.2,3.6,1371.7-1.5z"/>
					</g>
					<polygon
						id="health-polygon"
						className="health-polygon building-polygon st2"
						points="880.1,233.8 1259.7,453.5 1620,245.5 1453.7,149.6 1446.5,129 1347.7,71.6 1341.4,75.2 1341.4,68.4 1242.5,11 1180.7,46.8 1180.7,60.3"
					/>
					<g className="building-caption _flip">
						<g>
							<g>
								<path className="st3" d="M1023.9,237.5l18.8,10.9c-1.5-3-0.5-6.4,4.9-9.5c7.3-4.2,17-4.1,24.8,0.5l20.5,11.9l-4.9,2.8l-19.8-11.5
						c-5.5-3.2-11.8-3.9-17.6-0.6c-5.4,3.1-6.2,7.3,0.5,11.2l19.1,11.1l-4.9,2.8l-46.3-26.9L1023.9,237.5z"/>
							</g>
							<g>
								<path className="st3" d="M1115.1,221.9l-23.4,13.5l1.1,0.7c5.6,3.3,13.1,4.2,20,0.3c4.9-2.8,5.3-6.1,2.7-8.8l4.3-2.5
						c4.8,4,3.4,9.5-3.4,13.5c-8.5,4.9-19.8,5.2-28.6,0.1l-5.2-3c-8.8-5.1-9.6-11.4-0.4-16.7c9.3-5.4,20-4.4,28,0.2L1115.1,221.9z
						 M1087,232.7l1,0.6l18.9-10.9l-1.5-0.9c-5.8-3.4-13-4.2-19.4-0.5C1079,225.1,1081,229.3,1087,232.7"/>
							</g>
							<g className="st5">
								<g>
									<path className="st3" d="M1173.2,151.3l18.8,10.9c-1.5-3-0.5-6.4,4.9-9.5c7.3-4.2,16.9-4.1,24.7,0.5l20.5,11.9l-4.9,2.8l-19.8-11.5
							c-5.5-3.2-11.8-3.9-17.6-0.6c-5.4,3.1-6.2,7.3,0.5,11.2l19.1,11.1l-4.9,2.8l-46.3-26.9L1173.2,151.3z"/>
								</g>
								<g>
									<path className="st3" d="M1156.6,166.1l9.3,5.4l8.4-4.8l3.9,2.3l-8.4,4.8l19.9,11.6c3.3,1.9,5.9,2,9.5-0.1l3.3-1.9l3.9,2.3
							l-4.1,2.4c-6.3,3.6-12,3.3-17.5,0.1l-19.9-11.6l-5.6,3.2l-3.9-2.3l5.6-3.2l-9.3-5.4L1156.6,166.1z"/>
								</g>
								<g>
									<polygon className="st3" points="1133.9,174 1180.2,200.9 1175.4,203.7 1129.1,176.8 					"/>
								</g>
								<g>
									<path className="st3" d="M1118.9,197.8c8.1-4.7,18.2-4.4,24.6-0.7l21.6,12.5l-4.7,2.7l-4.1-2.4c0.9,2.1-1,6.2-6.4,9.3
							c-7.7,4.5-16.7,4.6-22.6,1.2c-6.3-3.6-5-8.9,2.8-13.4c5.2-3,10.8-3.7,14.5-3.7l-6-3.5c-4.4-2.6-10.8-2.9-16,0.1
							c-4.2,2.4-4.9,5.1-3.3,7.6l-4.5,2.6C1111.1,206.1,1112.4,201.5,1118.9,197.8z M1147.9,216.8c5.1-2.9,7-7.5,2.9-10
							c-4.1-2.4-11.8-1.2-16.7,1.6c-5.4,3.1-6.5,6.7-2,9.3C1136.5,220.5,1142.8,219.8,1147.9,216.8"/>
								</g>
							</g>
							<g>
								<path className="st3" d="M1166.3,288.8l10.4,0.1l-0.1,3.3l-9.5-0.1c-0.1,3.6-3,7.3-9.4,11c-8.9,5.1-22,6.2-30.6,1.3
						c-6.7-3.9-6.3-8.4-3.4-12.7l-0.5,0c-4.6-0.1-9.7-0.6-13.5-2.8c-6.2-3.6-5.4-8.4,1.8-12.6c6.5-3.8,15.6-4.4,21.6-0.9l2.5,1.4
						l-4,2.3l-2.4-1.4c-3.8-2.2-9.9-2-14.2,0.5c-4.5,2.6-5.4,5.7-1.4,8c3,1.7,6.7,2.2,10.9,2.2l35.8,0.4c-1.1-1.4-2.8-2.9-5.3-4.4
						l-4.5-2.6l4.1-2.4l4.6,2.7C1162.8,284.1,1165.2,286.4,1166.3,288.8z M1155.3,300.5c4.3-2.5,6.6-5.5,6.3-8.4l-32.9-0.3
						c-2.2,3.2-2.8,6.8,2.9,10.1C1138.1,305.6,1147.4,305,1155.3,300.5"/>
							</g>
							<g>
								<polygon className="st3" points="1226.4,312.9 1250,337 1245.4,339.6 1209.3,328.7 1228,349.7 1223.4,352.3 1182,338.6
						1186.6,335.9 1220.2,347.5 1201.9,327.1 1206.5,324.4 1241.6,335.1 1221.8,315.6 				"/>
							</g>
							<g>
								<path className="st3" d="M1278.3,304.1l-23.4,13.5l1.1,0.7c5.6,3.3,13.1,4.2,20,0.3c4.9-2.8,5.3-6.1,2.7-8.8l4.3-2.5
						c4.8,4,3.4,9.5-3.4,13.5c-8.5,4.9-19.8,5.2-28.6,0.1l-5.2-3c-8.8-5.1-9.6-11.4-0.4-16.7c9.3-5.4,20-4.4,28,0.2L1278.3,304.1z
						 M1250.2,314.9l1,0.6l18.9-10.9l-1.5-0.9c-5.8-3.4-13-4.2-19.4-0.5C1242.2,307.2,1244.3,311.4,1250.2,314.9"/>
							</g>
							<g>
								<polygon className="st3" points="1276.2,268.2 1322.5,295.1 1317.6,297.9 1271.3,271 				"/>
							</g>
							<g>
								<polygon className="st3" points="1260.4,277.3 1306.7,304.2 1301.8,307 1255.5,280.1 				"/>
							</g>
							<g>
								<path className="st3" d="M1418.1,201.4c6.8-3.9,16.3-4.6,22.3-1.1l0.3,0.2l-4.4,2.5l-0.2-0.1c-4-2.3-9.6-2.2-14.4,0.6
						c-4.5,2.6-6,5.6-2.3,7.7c2.2,1.3,4.7,1.5,7.6,0.4l12.4-4.4c6.2-2.2,11.7-2.5,16.4,0.2c5.8,3.4,4.3,9-3.3,13.5
						c-7.9,4.6-17.5,5.5-23.7,1.9l-0.3-0.2l4.4-2.5l0.2,0.1c4.1,2.4,10.6,1.7,15.9-1.4c5-2.9,6.7-6.2,2.5-8.6
						c-2.3-1.3-5.4-1.4-8.6-0.3l-11.9,4.3c-5.9,2.2-11.7,2.1-15.9-0.4C1408.9,210.4,1410.9,205.6,1418.1,201.4z"/>
							</g>
							<g>
								<path className="st3" d="M1384.5,220.8c6.8-3.9,16.3-4.6,22.3-1.1l0.3,0.2l-4.4,2.5l-0.2-0.1c-4-2.3-9.6-2.2-14.4,0.6
						c-4.5,2.6-6,5.6-2.3,7.7c2.2,1.3,4.7,1.5,7.6,0.4l12.4-4.4c6.2-2.2,11.7-2.5,16.4,0.2c5.8,3.4,4.3,9-3.3,13.5
						c-7.9,4.6-17.5,5.5-23.7,1.9l-0.3-0.2l4.4-2.5l0.2,0.1c4.1,2.4,10.6,1.7,15.9-1.4c5-2.9,6.7-6.2,2.5-8.6
						c-2.3-1.3-5.4-1.4-8.6-0.3l-11.9,4.3c-5.9,2.2-11.7,2.1-15.9-0.4C1375.3,229.8,1377.3,225,1384.5,220.8z"/>
							</g>
							<g>
								<path className="st3" d="M1382.9,243.7l-23.4,13.5l1.1,0.7c5.6,3.3,13.1,4.2,20,0.3c4.9-2.8,5.3-6.1,2.7-8.8l4.3-2.5
						c4.8,4,3.5,9.5-3.4,13.5c-8.5,4.9-19.8,5.2-28.6,0.1l-5.2-3c-8.8-5.1-9.6-11.4-0.4-16.7c9.3-5.4,20-4.4,28,0.2L1382.9,243.7z
						 M1354.8,254.5l1,0.6l18.9-10.9l-1.5-0.9c-5.8-3.4-13-4.2-19.4-0.5C1346.8,246.9,1348.8,251,1354.8,254.5"/>
							</g>
							<g>
								<path className="st3" d="M1315.4,260.7c7.3-4.2,16.9-4.1,24.7,0.5l20.5,11.9l-4.9,2.8l-19.8-11.5c-5.5-3.2-11.8-3.9-17.6-0.6
						c-5.4,3.1-6.2,7.3,0.5,11.2l19.1,11.1l-4.9,2.8l-32.5-18.9l4.6-2.7l5.5,3.2C1308.6,267.6,1309.8,264,1315.4,260.7z"/>
							</g>
						</g>
					</g>
				</g>

			</svg>
			}

		</div>
	</div>
);

class Content extends Component {
	constructor(props) {
		super(props);

		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.onIllustrationClick = this.onIllustrationClick.bind(this);
		this.toggleBuildingType = this.toggleBuildingType.bind(this);
		this.toggleSvgBuildingType = this.toggleSvgBuildingType.bind(this);

		this.props.getCountry();
	}

	componentDidMount() {
		$(document).on('mouseenter', '.active-step-1 label', (e) => {
			this.toggleSvgBuildingType(e.target, true)
		});

		$(document).on('mouseleave', '.active-step-1 label', (e) => {
			this.toggleSvgBuildingType(e.target, false)
		});

		$(document).on('click', '.list-item', (e) => {
			$('.list-item').removeClass('checked');
			e.currentTarget.classList.add('checked');
		});
	}

	toggleSvgBuildingType(el, state) {
		const id = el.getAttribute('for');
		const value = document.getElementById(id).value;
		const svg = document.querySelector(`[data-type="${value}"]`);

		svg.classList.toggle('_group-hover', state);
	}

	steps = ViewsJson.map(({options = [], ...rest}, index) => ({
		...rest,
		index,
		options: options.map((view_id) => {
			const isIdMatch = (view_item) => (
				view_item.id === view_id
			);

			return ViewsOptions.find(isIdMatch);
		})
	}));

	routes() {
		const {
			data,
			setStep,
			setProp
		} = this.props;

		return this.steps.map((view_type, index) => {
			const render = (props) => (
				<Views {...props}
					   {...view_type}
					   items={view_type.options}
					   next_step_data={ViewsJson[index + 1]}
					   prev_step_data={ViewsJson[index - 1]}
					   data={data}
					   setStep={setStep}
					   setProp={setProp}
				/>
			);

			return(
				<Route path={`/${view_type.path}`}
					   render={render}
					   key={`${view_type.id}_route`}
				/>
			)
		});
	}

	toggleBuildingType(el, state) {
		const type = el.dataset.type;
		const inputElByValue = document.querySelector(`.active-step-1 .choice-list [value="${type}"]`);

		if (inputElByValue) {
			inputElByValue.closest('.list-item')
				.classList.toggle('list-item-hover', state)
		}

		el.classList.toggle('_group-hover', state)
	}

	onMouseEnter(e) {
		this.toggleBuildingType(e.currentTarget, true);
	}

	onMouseLeave(e) {
		this.toggleBuildingType(e.currentTarget, false);
	}

	onIllustrationClick(e) {
		const type = e.currentTarget.dataset.type;

		this.props.setProp('building_type', e.currentTarget.dataset.type);

		const inputElByValue = document.querySelector(`.active-step-1 .choice-list [value="${type}"]`);

		if (inputElByValue) {
			$('.list-item').removeClass('checked');

			inputElByValue.closest('.list-item')
				.classList.add('checked')
		}
	}

	render() {
		const {
			step,
			last_step,
			data,
			setStep
		} = this.props;

		return(
			<div className="full-height">
				<TopBar />

				<div className={`page-content active-step-${step + 1}`}>
					<div className="navigation">
						<ConfigMenu
							current_step={step}
							onRouteChange={setStep}
						/>
					</div>
					<div className="content">
						{step !== null && step !== last_step &&
							<Illustration
								step={step}
								building_type={data.building_type}
								onMouseEnter={this.onMouseEnter}
								onMouseLeave={this.onMouseLeave}
								onClick={this.onIllustrationClick}
							/>
						}

						{this.routes()}

						<Route path="/estimated-cost/:id?" component={({match}) => (
							<EstimatedCost
								params={match.params}
								steps={this.steps}
								setStep={setStep}
							/>
						)}/>
					</div>
				</div>

				<CallBack />
			</div>
		);
	}
}

Content.defaultProps = {
	last_step: 5
};

const mapStateToProps = (state) => ({
	...state.main
});

const mapDispatchToProps = (dispatch) => ({
	setStep: step => dispatch(
		Actions.setStep(step)
	),
	setProp: (prop, value, full_value) => {
		dispatch(
			Actions.onChange(prop, value, full_value)
		)
	},
	getCountry: () => {
		dispatch(
			Actions.fetchCountry()
		)
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Content);