import React from 'react';

const SharePopup = ({is_sent, is_copied, onClose}) => (
	<div className="popup popup-share popup-show">
		<div className="popup-window">
			<a href="#" className="popup-close" onClick={onClose}/>
			<div className="popup-icon _share" />

			<div className="popup-title">Share Configuration</div>
			<div className="popup-subtitle">Use the buttons below to share your configuration.</div>

			{is_copied &&
				<div className="popup-success">link copied to clipboard</div>
			}

			{is_sent &&
			<div className="popup-success">link sent to email</div>
			}

			<a href="#" className="send-link">Send this link to my email</a>
			<a href="#" className="copy-direct-link">Copy Direct Link</a>
		</div>
	</div>
);

export default SharePopup;