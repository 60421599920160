import React, { Component } from 'react';

import ListItem from "../components/configurator/list_item.jsx";
import ConfigButtons from "../components/configurator/config_buttons";

class Views extends Component {
	componentDidMount() {
		const {
			id,
			setStep
		} = this.props;

		setStep(id);
	}

	get options() {
		const {
			items,
			data,
			setProp
		} = this.props;

		return items.map(view => (
			<ListItem
				key={`view_option_${view.id}`}
				{...view}
				data={data}
				setProp={setProp}
			/>
		))
	}

	render() {
		const {
			id,
			title,
			subtitle,
			items,
			prev_step_data,
			next_step_data,
			history,
			setStep
		} = this.props;

		if (items.length === 0) {
			return null;
		}

		return(
			<div className="choice">
				<div className="choice-list">
					<div className="choice-head">
						<div className="choice-title">
							{/*{id + 1}. */}
							{title}
						</div>
						<div className="choice-subtitle">
							{subtitle}
						</div>
					</div>
					<div className="list">
						{this.options}
					</div>
				</div>

				<ConfigButtons
					id={id}
					prev_step_data={prev_step_data}
					next_step_data={next_step_data}
					historyPush={history.push}
					setStep={setStep}
				/>
			</div>
		)
	}
}

Views.defaultProps = {
	items: []
};

export default Views;