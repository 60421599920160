import { requestApi } from "../../actions";


export const SET_CONTACTS = 'SET_CONTACTS';

export const setContacts = data => ({
	type: SET_CONTACTS,
	data
});


export const TOGGLE_CODE_SEND_LOADING = 'TOGGLE_CODE_SEND_LOADING';

export const toggleCodeSendLoading = is_loading => ({
	type: TOGGLE_CODE_SEND_LOADING,
	is_loading
});


export const TOGGLE_CODE_CONFIRM_LOADING = 'TOGGLE_CODE_CONFIRM_LOADING';

export const toggleCodeConfirmLoading = is_loading => ({
	type: TOGGLE_CODE_CONFIRM_LOADING,
	is_loading
});


export const SET_CONTACTS_PROP = 'SET_CONTACTS_PROP';

export const setContactsProp = (prop, value) => ({
	type: SET_CONTACTS_PROP,
	prop,
	value
});


export const TOGGLE_VERIFY_CODE_SENT = 'TOGGLE_VERIFY_CODE_SENT';

export const toggleVerifyCodeSent = is_sent => ({
	type: TOGGLE_VERIFY_CODE_SENT,
	is_sent
});


export const TOGGLE_VERIFY_CODE_CONFIRM = 'TOGGLE_VERIFY_CODE_CONFIRM';

export const toggleVerifyCodeConfirm = is_confirmed => ({
	type: TOGGLE_VERIFY_CODE_CONFIRM,
	is_confirmed
});


export const TOGGLE_VERIFY_CODE_SEND_TIMEOUT = 'TOGGLE_VERIFY_CODE_SEND_TIMEOUT';

export const toggleVerifyCodeSendTimeout = (is_timeout) => ({
	type: TOGGLE_VERIFY_CODE_SEND_TIMEOUT,
	is_timeout
});


export const submitContacts = (data = {}) => (dispatch, getState) => {
	dispatch(
		setContacts(data)
	);
};

export const requestVerificationCode = (email) => (dispatch, getState) => {
	const is_code_sending = getState().contacts.is_verify_code_sending;

	if (is_code_sending) {
		return
	}

	dispatch(
		toggleCodeSendLoading(true)
	);

	setTimeout(() => {
		dispatch(
			toggleVerifyCodeSendTimeout(true)
		);

		dispatch(
			toggleCodeSendLoading(false)
		);
	}, 20000);

	requestApi('send_email_confirm', {email}, 'POST')
		.then(response => {
			console.log('confirm', response);

			if (!response.status) {
				console.warn('not sent');
				return;
			}

			dispatch(
				toggleCodeSendLoading(false)
			);

			dispatch(
				toggleVerifyCodeSent(true)
			)
		});
};

export const requestVerificationCodeConfirm = (data) => (dispatch, getState) => {
	const {
		is_verify_code_confirming,
		is_verify_code_confirm_error
	} = getState().contacts;

	const {
		email,
		code
	} = data;

	if (is_verify_code_confirming || is_verify_code_confirm_error) {
		return
	}


	dispatch(
		toggleCodeConfirmLoading(true)
	);

	requestApi('confirm_email', {
		email,
		code
	}, 'POST')
		.then(response => {
			console.log('confirm', response);

			dispatch(
				toggleCodeConfirmLoading(false)
			);

			if (!response.status) {
				console.warn('not confirmed');

				dispatch(
					setContactsProp('code', '')
				);

				dispatch(
					toggleVerifyCodeError(true)
				);

				return;
			}

			console.warn('confirmed');

			dispatch(
				setContacts(data)
			);

			dispatch(
				toggleVerifyCodeConfirm(true)
			)
		});
};

export const TOGGLE_VERIFY_CODE_ERROR = 'TOGGLE_VERIFY_CODE_ERROR';

export const toggleVerifyCodeError = (is_error) => ({
	type: TOGGLE_VERIFY_CODE_ERROR,
	is_error
});
