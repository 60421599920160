import React, { Component } from 'react';

export default class Approach extends Component {
	render() {
		console.log('props', this.props);

		return (
			<div>
				approach
			</div>
		)
	}
}