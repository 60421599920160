import React from 'react';
import {NavLink} from "react-router-dom";

import Projects from './our_projects/projects';
import Videos from './our_projects/videos';

const OurProjects = () => (
	<section className="projects common-space-top">
		<div className="container-fluid">
			<div className="container _narrow" id="projects">
				<div className="row">
					<div className="col">
						<h2 className="heading">Our Projects</h2>
						<p className="heading-description">Have a look at our works.</p>
					</div>
				</div>
			</div>

			<Projects />

			<div className="projects-steps-container container _narrow common-space-top">
				<h3 className="heading">Three easy steps</h3>
				<h4 className="heading-description">Get price of your project.</h4>
				<div className="projects-steps row justify-content-between">
					<div className="col">
						<p className="projects-steps-number">01</p>
						<p className="projects-steps-title">Initial Data</p>
						<p className="projects-steps-description">
							Provide your initial data.
						</p>
					</div>
					<div className="col">
						<p className="projects-steps-number">02</p>
						<p className="projects-steps-title">Technical Offer</p>
						<p className="projects-steps-description">
							Get the technical offer with service description
						</p>
					</div>
					<div className="col">
						<p className="projects-steps-number">03</p>
						<p className="projects-steps-title">Get the price</p>
						<p className="projects-steps-description">
							Wait for the call from your personal account manager from DELTA
						</p>
					</div>
				</div>
				<div className="btn-container text-center">
					<NavLink replace to={`/building-type`} className="btn btn-red gtm_click_projects_4">GET THE OFFER</NavLink>
				</div>
			</div>

			<Videos />

			<div className="projects-bottom row">
				<div className="projects-bottom-image _image-1 col" />
				<div className="projects-bottom-image _image-2 col" />
				<div className="projects-bottom-image _image-3 col" />
			</div>
		</div>
	</section>
);

export default OurProjects;