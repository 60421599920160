import * as Actions from './actions';

export const initialState = {
	data: {
		full_name: '',
		company_name: '',
		phone: '',
		email: '',
		code: ''
	},
	is_submitted: false,
	is_verify_code_sending: false,
	is_verify_code_sent: false,
	is_verify_code_confirming: false,
	is_verify_code_confirmed: false,
	is_verify_code_send_timeout: false
};

export function reducer(state = initialState, action) {
	switch (action.type) {
		case Actions.SET_CONTACTS:
			return {
				...state,
				...action.data,
				is_submitted: true
			};

		case Actions.SET_CONTACTS_PROP:
			return {
				...state,
				data: {
					...state.data,
					[action.prop]: action.value
				}
			};

		case Actions.TOGGLE_CODE_SEND_LOADING:
			return {
				...state,
				is_verify_code_sending: action.is_loading
			};

		case Actions.TOGGLE_CODE_CONFIRM_LOADING:
			return {
				...state,
				is_verify_code_confirming: action.is_loading
			};

		case Actions.TOGGLE_VERIFY_CODE_SENT:
			return {
				...state,
				is_verify_code_sent: action.is_sent
			};

		case Actions.TOGGLE_VERIFY_CODE_CONFIRM:
			return {
				...state,
				is_verify_code_confirmed: action.is_confirmed
			};

		case Actions.TOGGLE_VERIFY_CODE_ERROR:
			return {
				...state,
				is_verify_code_confirm_error: action.is_error
			};

		case Actions.TOGGLE_VERIFY_CODE_SEND_TIMEOUT:
			return {
				...state,
				is_verify_code_send_timeout: action.is_timeout
			};

		default:
			return state
	}
}