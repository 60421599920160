import React from 'react';
import {NavLink} from "react-router-dom";

const ServiceItem = ({index, title, children}) => (
	<div className="col">
		<p className="services-number">{index}</p>
		<p className="services-title">{title}</p>
		<p className="services-description">{children}</p>
	</div>
);

const Services = () => (
	<section className="services">
		<div className="container-fluid">
			<div className="services-top row">
				<div className="services-top-image _image-1 col" />
				<div className="services-top-image _image-3 col" />
				<div className="services-top-image _image-2 col" />
			</div>
		</div>

		<div className="services-block container _narrow common-space-top common-space-bottom">
			<div className="row">
				<div className="col">
					<h2 id="services" className="heading">Services</h2>
					<h3 className="heading-description">What we actually do.</h3>

					<div className="services-list row justify-content-between">
						<ServiceItem
							index="01"
							title="Architecture"
						>
							We provide the full scope of architectural services from the earliest planning stage to the development of design, its implementation, and successful project completion.
						</ServiceItem>

						<ServiceItem
							index="02"
							title="Design & Build"
						>
							By choosing the Design and Build option you get the unified approach to the development and implementation of the design ideas meanwhile reducing the interface risks and minimizing the administration costs.
						</ServiceItem>

						<ServiceItem
							index="03"
							title="Management"
						>
							Our qualified and experienced managers with their practical and comprehensive approach to every step of project implementation will ensure a secure foundation for the whole construction process.
						</ServiceItem>

						<ServiceItem
							index="04"
							title="Building Information Modeling"
						>
							We implement BIM to save your time and money meanwhile providing you with realistic models at every step of a project’s lifecycle and to enable a continuous, ongoing and interconnected planning process, from inception to implementation.
						</ServiceItem>

						<ServiceItem
							index="05"
							title="Tender Preparation, Procurement and Processing"
						>
							We provide comprehensive service and advice on each stage of your tender process, including the pre-qualification, organization of tender negotiations, analysis of received tender documents and support in the evaluation and selection process to satisfy your needs and requirements.
						</ServiceItem>

						<ServiceItem
							index="06"
							title="Datenpool information platform"
						>
							With Datenpool platform we provide you with a reliable distribution of documents, efficient communication between the project participants and security of your electronic data.
						</ServiceItem>
					</div>
				</div>
			</div>

			<div className="btn-container text-center">
				<NavLink replace to={`/building-type`} className="btn btn-outline gtm_click_services_3">GET THE OFFER</NavLink>
			</div>
		</div>
	</section>
);

export default Services;