import React, { Component } from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { Translate, withLocalize } from "react-localize-redux";
import TranslateHomepage from "./localization.json";
import '../landing/css/styles.scss';

// import '../landing/js/jquery-ui.min.js';
// import '../landing/js/bootstrap.min.js';
// import '../landing/js/smooth_scroll.js';
// import '../landing/js/scroll_trigger.js';
// import '../landing/js/touch_events.js';

// import Footer from "../components/footer";

import Header from './landing/header/index';
import Top from './landing/sections/top';
import AboutUs from './landing/sections/about_us';
import Services from './landing/sections/services';
import OurProjects from "./landing/sections/our_projects";
import Faq from "./landing/sections/faq";
import Calculate from "./landing/sections/calculate";
import Offices from "./landing/sections/offices";
import Footer from './landing/footer/index';
import CallBack from "../components/call_back";

class Main extends Component {

	constructor(props) {
		super(props);

		this.props.initialize({
			languages: [
				{ name: "English", code: "en" },
				{ name: "Українська", code: "ua" }
			],
			translation: TranslateHomepage,
			options: {
				renderToStaticMarkup,
				renderInnerHtml: true,
				defaultLanguage: "en"
			}
		});
	}

	render() {
		return (
			<div className="homepage">
				<div id="top" />
				<Header />
				<main>
					<Top />
					<AboutUs />
					<Services />
					<OurProjects />
					<Faq />
					<Calculate />
					<Offices />
				</main>
				<CallBack />
				<Footer />
			</div>
		);
	}
}

export default withLocalize(Main);