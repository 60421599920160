import * as Actions from './actions';

export const initialState = {
	id: null,
	step: 0,
	term: 18,
	data: {
		building_type: 'health_and_wellness',
		stage: 'green_field',
		services: [],
		location: 'austria',
		approach: 'red'
	},
	full_data: {
		location: 'Austria'
	},
	price: {
		total_price: 0
	},
	is_fetching: false,
	is_save_popup_visible: false,
	is_share_popup_visible: false,
	default_country: ''
};

export function reducer(state = initialState, action) {
	switch (action.type) {
		case Actions.SET_USER_DATA_FETCHING:
			return {
				...state,
				is_fetching: action.is_fetching
			};

		case Actions.SET_DATA:
			return {
				...state,
				...(action.data || initialState.data),
				is_save_popup_visible: false,
				is_share_popup_visible: false,
				is_fetched: true
			};

		case Actions.SET_STEP:
			return {
				...state,
				step: action.step
			};

		case Actions.SET_PRICE:
			return {
				...state,
				price: {
					...state.price,
					total_price: action.price,
					full_pricing: action.full_pricing
				}
			};

		case Actions.SET_PROP:
			return {
				...state,
				data: {
					...state.data,
					[action.prop]: action.value
				}
			};

		case Actions.SET_PROP_FULL:
			return {
				...state,
				full_data: {
					...state.full_data,
					[action.prop]: action.value
				}
			};

		case Actions.SET_PROJECT_ID:
			return {
				...state,
				id: action.id
			};

		case Actions.TOGGLE_SAVE_POPUP:
			return {
				...state,
				is_save_popup_visible: action.is_visible
			};

		case Actions.TOGGLE_SHARE_POPUP:
			return {
				...state,
				is_share_popup_visible: action.is_visible
			};

		case 'SET_DEFAULT_COUNTRY':
			return {
				...state,
				default_country: action.country
			}

		default:
			return state
	}
}