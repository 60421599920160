import $ from 'jquery';

const PRICE_PROPS = ['services'];
const AREA_PROP = 'building_area';
const TERM_PROP = '';

const API_PORT_DEV = '3000';
const API_PORT_PROD = '3003';

export const getPrice = (state) => (
	PRICE_PROPS.reduce((value, prop) => (
		value + state.main.data[prop]
	), 0)
);

export const getArea = (state) => (
	Number(state.main.data[AREA_PROP]) || 0
);

export const getTerm = (state) => (
	Number(state.main.term || state.main.data[TERM_PROP]) || 0
);

export const formatPrice = (value) => (
	'€' + (
		new Intl.NumberFormat('us-US', {maximumFractionDigits: 0}).format(value || 0)
	).replace(',', ' ')
);

export const formatNumber = (value) => (
	!isNaN(Number(value)) ?
		String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') :
		value
);

export const getShareLinkById = (id) => (
	id ?
		`${window.origin}/estimated-cost/${id}` :
		null
);

export const getApiPort = () => {
	if (process.env.NODE_ENV === 'production') {
		return API_PORT_PROD;
	} else {
		return API_PORT_DEV;
	}
};

export const getApiUrl = (target) => {
	if (process.env.NODE_ENV === 'production') {
		return `${window.location.origin}/${target}`;
	} else {
		return `http://localhost:${getApiPort()}/${target}`;
	}
};

export const getInvoiceUrl = params => {
	const data = $.param(params);
	return getApiUrl(`invoice?${data}`)
};