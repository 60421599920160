import React from 'react';

import {
	Accordion,
	AccordionItem,
	AccordionItemTitle,
	AccordionItemBody
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => (
	<section className="faq common-space-top common-space-bottom">
		<div className="container _narrow">
			<p id="faq" className="heading">FAQ</p>
			<p className="heading-description">Find quick answers.</p>
			<Accordion>
				<div className="faq-accordion">
					<div className="accordion" id="faq_accordion">
						<AccordionItem>
							<div className="card">
								<AccordionItemTitle>
									<div className="card-header" id="headingOne">
										<h5 className="mb-0">
											<button className="btn btn-faq collapsed" type="button"
													data-toggle="collapse" data-target="#collapseOne"
													aria-expanded="true" aria-controls="collapseOne">
												How can I get a price for my project?
											</button>
										</h5>
									</div>
								</AccordionItemTitle>

								<AccordionItemBody>
									<div className="card-body">
										With almost 40 years experience we well understand the requirements of
										the building industry – but first and foremost, we understand the needs
										and requirements of our customers. In order to realise these
										requirements in as a successful and optimal way as possible we have
										developed a range of products – each with the aim of taking into account
										in varying degrees, the economic, sustainable and social aspects. This
										enables DELTA to create added value.
									</div>
								</AccordionItemBody>
							</div>
						</AccordionItem>

						<AccordionItem>
							<div className="card">
								<AccordionItemTitle>
									<div className="card-header" id="headingTwo">
										<h5 className="mb-0">
											<button className="btn btn-faq collapsed" type="button"
													data-toggle="collapse" data-target="#collapseTwo"
													aria-expanded="false" aria-controls="collapseTwo">
												What if I need only Architecture help?
											</button>
										</h5>
									</div>
								</AccordionItemTitle>

								<AccordionItemBody>
									<div className="card-body">
										With almost 40 years experience we well understand the requirements of
										the building industry – but first and foremost, we understand the needs
										and requirements of our customers. In order to realise these
										requirements in as a successful and optimal way as possible we have
										developed a range of products – each with the aim of taking into account
										in varying degrees, the economic, sustainable and social aspects. This
										enables DELTA to create added value.
									</div>
								</AccordionItemBody>
							</div>
						</AccordionItem>

						<AccordionItem>
							<div className="card">
								<AccordionItemTitle>
									<div className="card-header" id="headingThree">
										<h5 className="mb-0">
											<button className="btn btn-faq collapsed" type="button"
													data-toggle="collapse" data-target="#collapseThree"
													aria-expanded="false" aria-controls="collapseThree">
												Who is DELTA?
											</button>
										</h5>
									</div>
								</AccordionItemTitle>

								<AccordionItemBody>
									<div className="card-body">
										With almost 40 years experience we well understand the requirements of
										the building industry – but first and foremost, we understand the needs
										and requirements of our customers. In order to realise these
										requirements in as a successful and optimal way as possible we have
										developed a range of products – each with the aim of taking into account
										in varying degrees, the economic, sustainable and social aspects. This
										enables DELTA to create added value.
									</div>
								</AccordionItemBody>
							</div>
						</AccordionItem>

						<AccordionItem>

							<div className="card">
								<AccordionItemTitle>
									<div className="card-header" id="headingFour">
										<h5 className="mb-0">
											<button className="btn btn-faq collapsed" type="button"
													data-toggle="collapse" data-target="#collapseFour"
													aria-expanded="false" aria-controls="collapseFour">
												What the developing technology do you use?
											</button>
										</h5>
									</div>
								</AccordionItemTitle>

								<AccordionItemBody>
									<div className="card-body">
										With almost 40 years experience we well understand the requirements of
										the building industry – but first and foremost, we understand the needs
										and requirements of our customers. In order to realise these
										requirements in as a successful and optimal way as possible we have
										developed a range of products – each with the aim of taking into account
										in varying degrees, the economic, sustainable and social aspects. This
										enables DELTA to create added value.
									</div>
								</AccordionItemBody>
							</div>
						</AccordionItem>
					</div>
				</div>
			</Accordion>
		</div>
	</section>
);

export default Faq;