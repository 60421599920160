import React from 'react';

import Subscribe from "./subscribe";

const Footer = () => (
	<footer className="footer container _narrow">
		<div className="footer-top row">
			<Subscribe />

			<div className="footer-links row col-md-6">
				<div className="footer-link-block col-md-4 col-6">
					<div><a href="https://www.delta.at/en/company/" target="_blank" className="footer-link">About
						Us</a></div>
					<div><a href="https://www.delta.at/en/service-and-contact/contact/" target="_blank"
							className="footer-link">Contact Us</a></div>
					<div><a href="https://www.delta.at/en/service-and-contact/press-room/press-contact/"
							target="_blank" className="footer-link">Press Room</a></div>
					<div><a href="https://www.delta.at/en/service-and-contact/downloads/" target="_blank"
							className="footer-link">Downloads</a></div>
				</div>
				<div className="footer-link-block col-md-4 col-6">
					<div><a href="https://www.delta.at/en/products/red-line/" target="_blank"
							className="footer-link">Red Line</a></div>
					<div><a href="https://www.delta.at/en/products/green-line/" target="_blank"
							className="footer-link">Green Line</a></div>
					<div><a href="https://www.delta.at/en/products/teambau/" target="_blank"
							className="footer-link">Teambau</a></div>
					<div><a href="https://www.delta.at/en/products/datenpool/" target="_blank"
							className="footer-link">Datenpool</a></div>
				</div>
				<div className="footer-link-block col-md-4 col-6">
					<div><a href="https://www.delta.at/en/services/building-information-modeling-bim/"
							target="_blank" className="footer-link">Building Information</a></div>
					<div><a href="https://www.delta.at/en/services/architecture/" target="_blank"
							className="footer-link">Architecture</a></div>
					<div><a href="https://www.delta.at/en/services/design-and-build/" target="_blank"
							className="footer-link">Design & Build</a></div>
					<div><a href="https://www.delta.at/en/services/construction-management/" target="_blank"
							className="footer-link">Construction</a></div>
					<div><a href="https://www.delta.at/en/services/procurement-management/" target="_blank"
							className="footer-link">Procurement</a></div>
					<div><a href="https://www.delta.at/en/services/it-services/" target="_blank"
							className="footer-link">IT Services</a></div>
				</div>
			</div>
		</div>
		<div className="footer-divider row">
			<div className="col" />
		</div>
		<div className="footer-bottom row justify-content-between">
			<div className="copyright col">
				Copyright &copy;
				<script>document.write(new Date().getFullYear())</script>
				DELTA
			</div>
			<div className="footer-social social">
				<a rel="noopener noreferrer"
				   href="https://www.facebook.com/DELTA-Gesamtdienstleister-f%C3%BCr-den-Bau-910258715790223/"
				   className="social-link _facebook"
				   target="_blank"
				   title="Facebook"
				/>

				<a rel="noopener noreferrer"
				   href="https://www.instagram.com/delta_gruppe/"
				   className="social-link _instagram"
				   target="_blank" title="Instagram"
				/>

				<a rel="noopener noreferrer"
				   href="https://www.youtube.com/channel/UC80NTo30IAV5xrx-3FJVidw"
				   title="YouTube"
				   className="social-link _youtube"
				   target="_blank"
				/>
			</div>
		</div>
	</footer>
);

export default Footer;