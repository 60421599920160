import React, {Component} from 'react';
import cn from 'classnames';
import $ from 'jquery';

const Languages = ({is_menu_open, toggleMenu}) => (
	<div className={cn(
		"languages",
		{
			'lang-close': !is_menu_open,
			'lang-open': is_menu_open
		}
	)}
		 onClick={toggleMenu}
	>
		<div className="lang-list">
			<a href="/">
				<img src={require("../../../landing/images/flags/ua.gif")} /> Українська
			</a>
		</div>

		<img src={require("../../../landing/images/flags/gb.gif")} /> English
	</div>
);

class LanguagesContainer extends Component {
	constructor(props) {
		super(props);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.onOutsideClick = this.onOutsideClick.bind(this);
	}

	state = {
		is_menu_open: false
	};

	componentDidMount() {
		$(document).on('click touch', this.onOutsideClick);
	}

	componentWillUnmount() {
		$(document).off('click touch', this.onOutsideClick);
	}

	onOutsideClick(e) {
		const lang_button = $('.languages');

		if (!lang_button.is(e.target) && lang_button.has(e.target).length === 0) {
			this.toggleMenu(false);
		}
	}

	toggleMenu(state) {
		this.setState({
			is_menu_open: (
				typeof state !== 'undefined' ?
					state :
					!this.state.is_menu_open
			)
		});
	}

	render() {
		return (
			<Languages
				{...this.state}
				toggleMenu={() => this.toggleMenu()}
			/>
		);
	}
}

export default LanguagesContainer;