import React from 'react';
import { NavLink } from "react-router-dom";

import ViewsJson from "./views.json";

const ConfigMenu = ({onRouteChange}) => (
	<ul>
		{ViewsJson.map(viewTypeItem => (
			<li key={`submenu_item_${viewTypeItem.id}`}>
				<NavLink
					replace
					to={`/${viewTypeItem.path}`}
					onClick={() => onRouteChange(viewTypeItem.id)}
				>
					{viewTypeItem.path_title}
				</NavLink>
			</li>
		))}
	</ul>
);

export default ConfigMenu;