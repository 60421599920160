import React, { Component } from 'react';
//import LanguageSelect from "./language_select";

class TopBar extends Component {
	state = {
		isActive: true,
		isLangActive: true
	};

	ToggleClass = () => {
		this.setState({ isActive: !this.state.isActive })
	};

	ToggleLang = () => {
		this.setState({ isLangActive: !this.state.isLangActive })
	};

	render() {
		return(
			<div className={`top-bar container`}>
				<div className="social">
					<a rel="noopener noreferrer"
					   href="https://www.facebook.com/DELTA-Gesamtdienstleister-f%C3%BCr-den-Bau-910258715790223/"
					   className="social-link _facebook"
					   target="_blank"
					   title="Facebook"
					/>

					<a rel="noopener noreferrer"
					   href="https://www.instagram.com/delta_gruppe/"
					   className="social-link _instagram"
					   target="_blank" title="Instagram"
					/>

					<a rel="noopener noreferrer"
					   href="https://www.youtube.com/channel/UC80NTo30IAV5xrx-3FJVidw"
					   title="YouTube"
					   className="social-link _youtube"
					   target="_blank"
					/>
				</div>
				<div className="main-nav">
					<div className="language">
						<div className={`languages ${(this.state.isLangActive) ? 'lang-close' : 'lang-open'}`} onClick={this.ToggleLang}>
							<div className="lang-list">
								<a href="#">
									<img src={require(`../../images/flags/ua.gif`)} /> Українська
								</a>
							</div>
							<img src={require(`../../images/flags/gb.gif`)} /> English
						</div>
					</div>
					<div className="logo" />
				</div>
			</div>
		)
	}
}

export default TopBar;