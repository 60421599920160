import React from 'react';
import {NavLink} from "react-router-dom";

const AboutUs = () => (
	<section className="about common-space-top common-space-bottom">
		<div className="container _narrow">
			<div className="row justify-content-between">
				<div className="about-text">
					<h2 id="about" className="heading">About Us</h2>
					<h3 className="heading-description">Meet your experts in building.</h3>
					<div className="about-description">
						Our dedicated team of highly-skilled professionals will provide you with an outstanding service ensuring the security and the highest quality, being a strong and reliable partner for your project. Every year we handle construction projects with a total investment volume of more than 4,5 billion Euro.
					</div>
					<div className="about-description">
						Our daily work is built on and guided by the values and principles of sustainability, innovativeness, respect, reliability, accountability and trust, and our well-thought-out and comprehensive approach forms the basis to the success of your project.
					</div>
				</div>
				<div className="about-graphic">
					<div className="about-image" />
				</div>
			</div>
			<div className="numbers row align-items-center">
				<div className="numbers-col col _projects">
					<p className="numbers-amount">291</p>
					<p className="numbers-projects">Projects</p>
				</div>
				<div className="numbers-split">/</div>
				<div className="numbers-col col _clients">
					<p className="numbers-amount">242</p>
					<p className="numbers-projects">Happy Clients</p>
				</div>
				<div className="numbers-split">/</div>
				<div className="numbers-col col _awards">
					<p className="numbers-amount">9</p>
					<p className="numbers-projects">Awards</p>
				</div>
				<div className="numbers-split">/</div>
				<div className="numbers-col col _offices">
					<p className="numbers-amount">9</p>
					<p className="numbers-projects">Offices</p>
				</div>
			</div>
			<div className="btn-container text-center">
				<NavLink replace to={`/building-type`} className="btn btn-red gtm_click_about_2">GET THE OFFER</NavLink>
			</div>
		</div>
	</section>
);

export default AboutUs;