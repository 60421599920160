import React from 'react';

import Languages from './languages';

const HeaderNavItem = ({children, href}) => (
	<li>
		<a className="nav-link menu-link gtm_menu_click"
		   href={`#${href}`}
		>
			{children}
		</a>
	</li>
);

const HeaderNav = () => (
	<nav id="navigation" className="landing-navigation container">
		<ul>
			<HeaderNavItem href="about">ABOUT US</HeaderNavItem>
			<HeaderNavItem href="services">SERVICES</HeaderNavItem>
			<HeaderNavItem href="projects">
				<span className="nav-link-hide">OUR</span> PROJECTS
			</HeaderNavItem>
			{/*<HeaderNavItem href="services">SERVICES</HeaderNavItem>*/}
			{/*<HeaderNavItem href="videos">PROJECT VIDEOS</HeaderNavItem>*/}
			<HeaderNavItem href="faq">FAQ</HeaderNavItem>
			<HeaderNavItem href="contact">CONTACT US</HeaderNavItem>
		</ul>

		<Languages />

		<a href="#top" className="nav-link js-back-to-top back-to-top">&uarr;</a>
	</nav>
);

export default HeaderNav;