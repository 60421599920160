import React from 'react';

const Offices = () => (
	<section id="offices" className="offices common-space-top common-space-bottom">
		<div className="container _narrow">
			<h3 className="heading">Our offices</h3>
			<div className="offices-list row justify-content-between">
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Wels</div>
					<div className="offices-address">
						Kalkofenstraße 21<br />
						A - 4600 Wels,<br />
						Austria
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Vienna</div>
					<div className="offices-address">
						Zaunergasse 4<br />
						A - 1030 Vienna,<br />
						Austria
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Hagenberg</div>
					<div className="offices-address">
						Softwarepark 26<br />
						A - 4232 Hagenberg<br />
						Austria
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Fischamend</div>
					<div className="offices-address">
						Am Rosenhügel 41<br />
						A - 2401 Fischamend<br />
						Austria
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Bratislava</div>
					<div className="offices-address">
						Údernicka 11<br />
						SK - 85101 Bratislava,<br />
						Slowakia
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Trebic</div>
					<div className="offices-address">
						Komenského nám. 1342/7<br />
						CZ - 67401 Trebic,<br />
						Czech Republic
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Praha</div>
					<div className="offices-address">
						Na Pankráci 1724/129<br />
						CZ - 140 00 Praha 4<br />
						Czech Republic
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Kyiv</div>
					<div className="offices-address">
						13 Pymonenko St.<br />
						UA - 04050 Kyiv,<br />
						Ukraine
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6">
					<div className="offices-location">Lviv</div>
					<div className="offices-address">
						Shota Rustaveli, 12<br />
						UA - 79044 Lviv<br />
						Ukraine
					</div>
				</div>
				<div className="col-md-3 col-sm-4 col-6 _hide-600">
				</div>
				<div className="col-md-3 col-sm-4 col-6 _hide-600">
				</div>
			</div>
		</div>
	</section>
);

export default Offices;